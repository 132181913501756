<template>
  <div>
    <k-panelbar
      class="custom"
      expandMode="multiple"
      :items="PANEL_BAR_ITEMS"
      :expanded="PANEL_BAR_ITEMS.map(({ id }) => id)"
    >
      <!-- Search Panel -->
      <template v-slot:search>
        <li>
          <span class="k-link" @click="() => onSelect(PANEL_BAR_KEYS.SEARCH)">
            <span class="k-panelbar-item-text">
              <div v-if="panelBar === PANEL_BAR_KEYS.SEARCH" class="row">
                <div class="col-1 d-flex align-items-center">
                  <i
                    @click="doSearch"
                    class="k-icon k-i-search k-icon-24 k-link text-primary"
                  ></i>
                </div>
                <div class="col-11 ps-1">
                  <k-inputs
                    type="text"
                    class="unset-background custom-search-field border-0 mb-0"
                    v-on:keyup.enter="doSearch"
                    v-model="searchKeyword"
                    placeholder="Search"
                  />
                </div>
              </div>
              <div v-else class="v-row col-12 d-flex align-items-center">
                <i class="k-icon k-i-search k-icon-24 me-2"></i> Search
              </div>
            </span>
            <span
              :class="[
                'k-icon k-panelbar-toggle k-panelbar-expand',
                {
                  'k-i-arrow-chevron-down': panelBar !== PANEL_BAR_KEYS.SEARCH,
                  'k-i-arrow-chevron-up': panelBar === PANEL_BAR_KEYS.SEARCH
                }
              ]"
              @click="(e) => onClickPanelArrow(e, PANEL_BAR_KEYS.SEARCH)"
            ></span>
          </span>
          <div
            class="row m-0 content"
            v-if="panelBar === PANEL_BAR_KEYS.SEARCH"
          >
            <div class="col-12 px-0" v-if="searchTotal > 0">
              <span class="ps-4">{{ keyword }}</span>
              <span class="dashboard--color1">({{ searchTotal }})</span>
              <div class="grid-search custom-scrollbar px-4">
                <div
                  v-for="item in searchResponse"
                  :key="item.ticker_region"
                  @click="onResultClick(item)"
                  class="d-flex align-items-center k-link border border-light rounded my-2 p-2"
                >
                  <div class="col-9 dashboard--color3 text-truncate">
                    {{ item.isin === 'missing' ? 'N/A' : item.isin }}
                    {{ item.name }}
                  </div>
                  <div
                    class="col-3 dashboard--color2 d-flex justify-content-end"
                  >
                    {{ item.region_name }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="col-12 grid-search-empty">
              <div
                class="d-flex justify-content-center align-items-center text-center px-6 h-100"
              >
                <span v-if="keyword.length < 1"
                  >Please input at least 1 character in order to search for
                  specific items.</span
                >
                <span v-else-if="searchTotal < 1 && keyword.length > 0">
                  <div class="d-flex justify-content-between warning">
                    <div class="warning-icon">
                      <icon-warning></icon-warning>
                    </div>
                    <div class="warning-message">
                      <template v-if="!isResponseOverloaded">
                        There are no results to be displayed. Please adjust your
                        keyword.
                      </template>
                      <template v-else
                        >Too many results. Please narrow your search!</template
                      >
                    </div>
                  </div></span
                >
              </div>
            </div>
          </div>
        </li>
      </template>
      <!-- Watchlists Panel -->
      <template v-slot:watchLists>
        <li>
          <span
            class="k-link"
            @click="() => onSelect(PANEL_BAR_KEYS.WATCH_LISTS)"
          >
            <span class="k-panelbar-item-text">
              <div class="row">
                <div class="col-5 d-flex align-items-center">
                  <i
                    class="k-icon k-i-star-outline k-icon-24 me-2"
                    :class="panelBar == 1 ? 'text-primary' : ''"
                  ></i>
                  Watchlists
                </div>
                <div
                  v-if="panelBar == PANEL_BAR_KEYS.WATCH_LISTS"
                  class="col-7 text-end"
                >
                  <k-button
                    class="custom-button-icon"
                    theme-color="secondary"
                    fill-mode="solid"
                    rounded="full"
                    @click="downloadWatchlist"
                    :disabled="selectedWatchlists.length == 0"
                    ><icon-file-arrow-down
                  /></k-button>
                  <k-button
                    class="custom-button-icon"
                    theme-color="secondary"
                    fill-mode="solid"
                    rounded="full"
                    @click="confirmDeleteWatchlistDialog = true"
                    :disabled="selectedWatchlists.length == 0"
                    ><icon-trash
                  /></k-button>
                  <k-button
                    class="ms-2"
                    theme-color="primary"
                    @click="$refs.watchlistFile.click()"
                    >Upload Watchlist</k-button
                  >
                </div>
              </div>
            </span>
            <span
              :class="[
                'k-icon k-panelbar-toggle k-panelbar-expand',
                {
                  'k-i-arrow-chevron-down':
                    panelBar !== PANEL_BAR_KEYS.WATCH_LISTS,
                  'k-i-arrow-chevron-up':
                    panelBar === PANEL_BAR_KEYS.WATCH_LISTS
                }
              ]"
              @click="(e) => onClickPanelArrow(e, PANEL_BAR_KEYS.WATCH_LISTS)"
            ></span>
          </span>
          <div
            class="grid-watchlist content"
            v-if="panelBar == PANEL_BAR_KEYS.WATCH_LISTS"
          >
            <div class="row m-0">
              <div
                class="col-12 tab-filter watchlist-list custom-scrollbar p-4"
              >
                <div
                  v-for="(item, idx) in $store.state.watchlists"
                  :key="idx"
                  class="d-flex align-items-center custom secondary unset-background mb-2"
                >
                  <input
                    class="k-checkbox k-checkbox-md k-rounded-md"
                    type="checkbox"
                    :id="item.name"
                    :value="item.name"
                    v-model="selectedWatchlists"
                  />
                  <div
                    class="d-flex justify-content-between align-items-center border rounded w-100 ms-3 px-2"
                  >
                    <span class="text-white" v-text="item.name"></span>
                    <span
                      class="unset-min-width k-button k-button-link"
                      @click="loadWatchlist(item)"
                      >Load List</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-0 grid-watchlist_content">
              <div class="col-12 bg-info text-white">
                {{ watchlist.name }}
              </div>
              <div class="col-12 px-0 grid-watchlist_content_table">
                <k-grid
                  id="gridWatchlist"
                  class="p-0 m-0"
                  :data-items="watchlist.companies"
                  :columns="columnWatchlists"
                  @rowclick="onRowClickWatchlist"
                >
                  <k-grid-no-rec class="mt-6">
                    <b>There are no watchlists to be displayed.</b>
                    <p>Please load your watchlists.</p>
                  </k-grid-no-rec>
                  <template v-slot:scoreFormat="{ props }">
                    <td>
                      {{ Math.round(props.dataItem[props.field] * 100) }}%
                    </td>
                  </template>
                  <template v-slot:actionTemplate="{ props }">
                    <td colspan="1">
                      <div class="d-flex flex-row align-items-center">
                        <k-button
                          class="custom-button grid-btn m-0"
                          theme-color="inherit"
                          fill-mode="solid"
                          rounded="full"
                          @click="drawerVisible = true"
                        >
                          <k-tooltip
                            id="tooltip"
                            :title="hoverWatchlist(props.dataItem)"
                            :key="hoverWatchlist(props.dataItem)"
                            :position="'left'"
                          >
                            <span class="k-icon k-i-star text-white"></span>
                          </k-tooltip>
                        </k-button>
                      </div>
                    </td>
                  </template>
                </k-grid>
              </div>
            </div>
          </div>
        </li>
      </template>
      <!-- Filters Panel -->
      <template v-slot:filters>
        <li class="k-state-active">
          <span class="k-link" @click="() => onSelect(PANEL_BAR_KEYS.FILTERS)">
            <span class="k-panelbar-item-text">
              <div class="row">
                <div class="col-5 d-flex align-items-center">
                  <i
                    class="k-icon k-i-saturation k-icon-24 me-2"
                    :class="panelBar == 2 ? 'text-primary' : ''"
                  ></i>
                  Filters
                </div>
                <div
                  v-if="panelBar == PANEL_BAR_KEYS.FILTERS"
                  class="col-7 text-end"
                >
                  <span
                    class="unset-min-width k-button k-button-link me-3"
                    :class="selectedTab == 0 ? 'active-tab-filter' : ''"
                    @click="selectedTab = 0"
                    >Filter</span
                  >
                  <span
                    class="unset-min-width k-button k-button-link"
                    :class="selectedTab == 1 ? 'active-tab-filter' : ''"
                    @click="selectedTab = 1"
                    >Saved Filters</span
                  >
                </div>
              </div>
            </span>
            <span
              :class="[
                'k-icon k-panelbar-toggle k-panelbar-expand',
                {
                  'k-i-arrow-chevron-down': panelBar !== PANEL_BAR_KEYS.FILTERS,
                  'k-i-arrow-chevron-up': panelBar === PANEL_BAR_KEYS.FILTERS
                }
              ]"
              @click="(e) => onClickPanelArrow(e, PANEL_BAR_KEYS.FILTERS)"
            ></span>
          </span>
          <div
            class="sidebar-filter content"
            v-if="panelBar == PANEL_BAR_KEYS.FILTERS"
          >
            <div class="panel-active">
              <div
                :style="selectedTab == 0 ? '' : 'display: none;'"
                class="container px-2 tab-filter"
              >
                <div class="row ps-1 m-0 pb-0" v-if="showAdvFilter">
                  <!-- Region ------------------ Risk Score -->
                  <div class="row p-0">
                    <div class="col-5 pe-0">
                      <span class="filterfont">Region</span>
                      <span class="filterCountfont"> *</span>
                      <span v-if="count.regionCount" class="filterCountfont">
                        ({{ count.regionCount }})</span
                      >
                      <k-combobox
                        class="dropdown-rounded-left picker-with-bg custom-region"
                        :class="filter.regionId ? 'active-drop-down' : ''"
                        v-model="filter.regionId"
                        :data-source="region"
                        data-text-field="region_name"
                        data-value-field="regionID"
                        filter="contains"
                        placeholder="Select"
                        :clear-button="false"
                      >
                      </k-combobox>
                      <span v-if="statusRegion" class="errorfont">
                        {{ statusRegion }}</span
                      >
                    </div>
                    <div class="col-3 ps-1 pe-0">
                      <span class="filterfont">Rating</span>
                      <k-custom-multiselect
                        class="dropdown-rounded-left"
                        :class="
                          filter.rating.length > 0 ? 'active-drop-down' : ''
                        "
                        v-model="filter.rating"
                        placeholder="Select"
                        :source="rating"
                        :dropDownName="'RAT'"
                        :useLoader="loaderImage.rat"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="rating"
                        dataItemKey="rating"
                      />
                    </div>
                    <div v-if="rerender" class="col-4 ps-1 pe-0">
                      <span class="filterfont">Risk score</span>
                      <span v-if="count.riskScoreCount" class="filterCountfont"
                        >({{ count.riskScoreCount }})</span
                      >
                      <div class="d-flex justify-content-between d-block">
                        <!-- default range min & max -->
                        <span class="rangefontSize"
                          >{{ formatedNumber(riskScoreDefault[0])
                          }}<span
                            v-if="riskScore[0] != riskScoreDefault[0]"
                            class="text-white"
                            >({{ formatedNumber(riskScore[0]) }})</span
                          ></span
                        >
                        <span class="rangefontSize"
                          ><span
                            v-if="riskScore[1] != riskScoreDefault[1]"
                            class="text-white"
                            >({{ formatedNumber(riskScore[1]) }})</span
                          >{{ formatedNumber(riskScoreDefault[1]) }}</span
                        >
                        <!-- selected slider value -->
                        <!-- <span class="rangefontSize">{{ formatedNumber(riskScore[0]) }}</span>
												<span class="rangefontSize">{{ formatedNumber(riskScore[1]) }}</span> -->
                      </div>
                      <k-range-slider
                        @change="onChangeRiskScore"
                        id="riskScoreSlider"
                        :class="{ 'active-slider': sliderActiveRs }"
                        class="px-2"
                        v-model="riskScore"
                        :min="riskScoreDefault[0]"
                        :max="riskScoreDefault[1]"
                      >
                      </k-range-slider>
                    </div>
                  </div>
                  <!-- Sector - Industry Group - Auditor -->
                  <div class="row p-0">
                    <div class="col-4 pe-0">
                      <span class="filterfont"> Sector</span>
                      <span v-if="count.sectorCount" class="filterCountfont">
                        ({{ count.sectorCount }})</span
                      >
                      <k-custom-multiselect
                        class="dropdown-rounded-left"
                        :class="
                          filter.sectorId.length > 0 ? 'active-drop-down' : ''
                        "
                        v-model="filter.sectorId"
                        placeholder="Select"
                        :source="sector"
                        :dropDownName="'SEC'"
                        :useLoader="loaderImage.sec"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="sector_name"
                        dataItemKey="sectorID"
                      />
                    </div>
                    <div class="col-4 ps-1 pe-0">
                      <span class="filterfont"> Industry Group </span>
                      <span v-if="count.industryCount" class="filterCountfont">
                        ({{ count.industryCount }})</span
                      >
                      <k-custom-multiselect
                        class="dropdown-rounded-0"
                        :class="
                          filter.industryGroupId.length > 0
                            ? 'active-drop-down'
                            : ''
                        "
                        v-model="filter.industryGroupId"
                        placeholder="Select"
                        :source="industry"
                        :dropDownName="'IG'"
                        :useLoader="loaderImage.ig"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="industry_group_name"
                        dataItemKey="industry_groupID"
                      />
                    </div>
                    <div class="col-4 ps-1 pe-0">
                      <span class="filterfont"> Auditor </span>
                      <span v-if="count.auditorCount" class="filterCountfont">
                        ({{ count.auditorCount }})</span
                      >
                      <k-custom-multiselect
                        class="dropdown-rounded-right"
                        :class="
                          filter.auditorId.length > 0 ? 'active-drop-down' : ''
                        "
                        v-model="filter.auditorId"
                        placeholder="Select"
                        :source="auditor"
                        :dropDownName="'AUD'"
                        :useLoader="loaderImage.aud"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="auditor_name"
                        dataItemKey="auditorID"
                      />
                    </div>
                  </div>
                  <!-- Domicile - Exchange - Incorporation -->
                  <div class="row p-0">
                    <div class="col-4 pe-0">
                      <span class="filterfont"> Domicile </span>
                      <span v-if="count.domicileCount" class="filterCountfont">
                        ({{ count.domicileCount }})</span
                      >
                      <k-custom-multiselect
                        class="dropdown-rounded-left"
                        :class="
                          filter.domicile.length > 0 ? 'active-drop-down' : ''
                        "
                        v-model="filter.domicile"
                        placeholder="Select"
                        :source="domicile"
                        :dropDownName="'DOM'"
                        :useLoader="loaderImage.dom"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="domicile_country"
                        dataItemKey="domicile_country"
                      ></k-custom-multiselect>
                    </div>
                    <div class="col-4 ps-1 pe-0">
                      <span class="filterfont"> Exchange </span>
                      <span v-if="count.exchangeCount" class="filterCountfont">
                        ({{ count.exchangeCount }})</span
                      >
                      <k-custom-multiselect
                        class="dropdown-rounded-0"
                        :class="
                          filter.exchange.length > 0 ? 'active-drop-down' : ''
                        "
                        v-model="filter.exchange"
                        placeholder="Select"
                        :source="exchange"
                        :dropDownName="'EXC'"
                        :useLoader="loaderImage.exc"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="exchange_country"
                        dataItemKey="exchange_country"
                      ></k-custom-multiselect>
                    </div>
                    <div class="col-4 ps-1 pe-0">
                      <span class="filterfont"> Incorporation </span>
                      <span
                        v-if="count.incorporationCount"
                        class="filterCountfont"
                        >({{ count.incorporationCount }})</span
                      >
                      <k-custom-multiselect
                        class="dropdown-rounded-right"
                        :class="
                          filter.incorporation.length > 0
                            ? 'active-drop-down'
                            : ''
                        "
                        v-model="filter.incorporation"
                        placeholder="Select"
                        :source="incorporation"
                        :dropDownName="'INC'"
                        :useLoader="loaderImage.inc"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="incorporation_country"
                        dataItemKey="incorporation_country"
                      ></k-custom-multiselect>
                    </div>
                  </div>
                  <!-- Status - 1 Year Change - Market Cap Decile -->
                  <div class="row p-0">
                    <div class="col-4 pe-0">
                      <span class="filterfont"> Status </span>
                      <span v-if="count.statusCount" class="filterCountfont">
                        ({{ count.statusCount }})</span
                      >
                      <k-custom-multiselect
                        class="dropdown-rounded-left"
                        :class="
                          filter.status.length > 0 ? 'active-drop-down' : ''
                        "
                        v-model="filter.status"
                        placeholder="Select"
                        :source="status"
                        :dropDownName="'STA'"
                        :useLoader="loaderImage.sta"
                        v-on:loadFilterOptions="loadFilterOptions"
                        textField="trading_status"
                        dataItemKey="trading_status"
                      ></k-custom-multiselect>
                    </div>
                    <div v-if="rerender" class="col-4 ps-1 pe-0">
                      <span class="filterfont"> 1 Year Change </span>
                      <span
                        v-if="count.oneYearChangeCount"
                        class="filterCountfont"
                      >
                        ({{ count.oneYearChangeCount }})</span
                      >
                      <div class="d-flex justify-content-between d-block">
                        <!-- default range min & max -->
                        <span class="rangefontSize"
                          >{{ formatedNumber(oneYearChangeDefault[0]) }}
                          <span
                            v-if="oneYearChange[0] != oneYearChangeDefault[0]"
                            class="text-white"
                            >({{ formatedNumber(oneYearChange[0]) }})</span
                          ></span
                        >
                        <span class="rangefontSize"
                          ><span
                            v-if="oneYearChange[1] != oneYearChangeDefault[1]"
                            class="text-white"
                            >({{ formatedNumber(oneYearChange[1]) }})</span
                          >
                          {{ formatedNumber(oneYearChangeDefault[1]) }}
                        </span>
                        <!-- selected slider value -->
                        <!-- <span class="rangefontSize">{{ formatedNumber(oneYearChange[0]) ? formatedNumber(oneYearChange[0]) : formatedNumber(oneYearChangeDefault[0]) }}</span>
												<span class="rangefontSize">{{ formatedNumber(oneYearChange[1]) ? formatedNumber(oneYearChange[1]) : formatedNumber(oneYearChangeDefault[1]) }}</span> -->
                      </div>
                      <k-range-slider
                        @change="onChangeOneYearChange"
                        v-model="oneYearChange"
                        :class="{ 'active-slider': sliderActiveOyc }"
                        class="px-2"
                        id="oneYearChangeSlider"
                        :min="oneYearChangeDefault[0]"
                        :max="oneYearChangeDefault[1]"
                      >
                      </k-range-slider>
                    </div>
                    <div v-if="rerender" class="col-4 ps-1 pe-0">
                      <span class="filterfont">
                        Market Cap Decile - 1=Large
                      </span>
                      <span
                        v-if="count.marketCapDecileCount"
                        class="filterCountfont"
                      >
                        ({{ count.marketCapDecileCount }})</span
                      >
                      <div class="d-flex justify-content-between d-block">
                        <!-- default range min & max || (selected value)-->
                        <span class="rangefontSize"
                          >{{ formatedNumber(marketCapDecileDefault[0])
                          }}<span
                            v-if="
                              marketCapDecile[0] != marketCapDecileDefault[0]
                            "
                            class="text-white"
                            >({{ formatedNumber(marketCapDecile[0]) }})</span
                          ></span
                        >
                        <span class="rangefontSize"
                          ><span
                            v-if="
                              marketCapDecile[1] != marketCapDecileDefault[1]
                            "
                            class="text-white"
                            >({{ formatedNumber(marketCapDecile[1]) }})</span
                          >{{ formatedNumber(marketCapDecileDefault[1]) }}</span
                        >
                        <!-- selected slider value -->
                        <!-- <span class="rangefontSize">{{ formatedNumber(marketCapDecile[0]) }}</span>
												<span class="rangefontSize">{{ formatedNumber(marketCapDecile[1]) }}</span> -->
                      </div>
                      <k-range-slider
                        @change="onChangeMarketCapDecile"
                        v-model="marketCapDecile"
                        :class="{ 'active-slider': sliderActiveMcd }"
                        class="px-2"
                        id="marketCapDecileSlider"
                        :min="marketCapDecileDefault[0]"
                        :max="marketCapDecileDefault[1]"
                      >
                      </k-range-slider>
                    </div>
                  </div>
                </div>
                <div class="row ps-1 m-0 pb-2">
                  <div class="row p-0">
                    <div class="col-4 pe-0 align-items-end">
                      <span class="filterfont">Year</span>
                      <!-- <span class="filterCountfont"> *</span> -->
                      <ComboBox
                        class="dropdown-rounded-left picker-with-bg custom-year"
                        :class="lastSelectedYear ? 'active-drop-down' : ''"
                        v-model="tempYear"
                        :data-items="year"
                        placeholder="Select"
                        :clear-button="false"
                        :loading="loaderImage.year"
                        :disabled="loaderImage.year"
                        @change="saveSelectedYear()"
                      >
                      </ComboBox>
                    </div>
                    <div
                      class="col-8 pe-0 d-flex align-items-end justify-content-end"
                    >
                      <!-- <div class="d-flex justify-content-end align align-items-center bg-white"> -->
                      <div class="pe-1">
                        <k-button
                          class="btn-without-min-width"
                          :fill-mode="'outline'"
                          theme-color="#8698ac"
                          @click.stop="clearAllFilter"
                        >
                          Clear
                        </k-button>
                      </div>
                      <div class="pe-1">
                        <k-button
                          theme-color="secondary"
                          @click.stop="saveFilterDialog = true"
                        >
                          Save Filter
                        </k-button>
                      </div>
                      <div class="pe-1">
                        <k-button
                          theme-color="primary"
                          @click.stop="checkAvailableYear()"
                          id="filterButton"
                        >
                          Apply Filter
                        </k-button>
                      </div>
                      <div class="pe-1">
                        <a
                          v-on:click="advFilter()"
                          class="filterfont text-decoration-underline"
                          v-text="advFilterText"
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                :style="selectedTab == 1 ? '' : 'display: none;'"
                class="tab-filter"
              >
                <div class="row m-0">
                  <div
                    class="col-12 px-4 py-2 d-flex justify-content-between align-items-center"
                  >
                    <span>Saved Filters</span>
                    <k-button
                      :disabled="selectedSavedFilters.length == 0"
                      @click="confirmDeleteSaveFilterDialog = true"
                      class="custom-button-icon"
                      theme-color="secondary"
                      :size="'small'"
                      fill-mode="solid"
                      rounded="full"
                      ><icon-trash
                    /></k-button>
                  </div>
                </div>
                <div class="row m-0">
                  <div class="col-12 saved-filter custom-scrollbar p-4">
                    <div
                      v-for="(filter, idx) in $store.state.filters"
                      :key="idx"
                      class="d-flex align-items-center custom secondary unset-background mb-2"
                    >
                      <input
                        class="k-checkbox k-checkbox-md k-rounded-md"
                        type="checkbox"
                        :id="filter.name"
                        :value="filter.name"
                        v-model="selectedSavedFilters"
                      />
                      <div
                        class="d-flex justify-content-between align-items-center border rounded w-100 ms-3 px-2"
                      >
                        <span class="text-white" v-text="filter.name"></span>
                        <span
                          class="unset-min-width k-button k-button-link"
                          @click="loadSavedFilter(filter)"
                          >Load Filter</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row ps-0 m-0 pb-0">
              <k-grid
                id="gridFilter"
                class="grid-filter p-0 m-0 grid-filter-min"
                :data-items="items"
                :columns="columns"
                :selected-field="selectedField"
                :sortable="true"
                :sort="sort"
                @sortchange="sortChangeHandler"
                :skip="skip"
                :take="take"
                :total="total"
                :pageable="pageable"
                :page-size="pageSize"
                @pagechange="pageChangeHandler"
                @rowclick="onRowClick"
              >
                <k-grid-no-rec class="mt-6">
                  <b>There are no results to be displayed.</b>
                  <p>Please adjust your filters.</p>
                </k-grid-no-rec>
                <template v-slot:yearTemplate>
                  <div class="d-flex align-items-center">
                    Risk {{ filter.year }}
                  </div>
                </template>

                <template v-slot:scoreFormat="{ props }">
                  <td class="ps-4">
                    {{ Math.round(props.dataItem[props.field] * 100) }}%
                  </td>
                </template>
                <template v-slot:actionHeaderTemplate></template>
                <template v-slot:actionTemplate="{ props }">
                  <td colspan="1">
                    <div class="d-flex flex-row align-items-center">
                      <k-button
                        class="custom-button grid-btn m-0"
                        theme-color="inherit"
                        fill-mode="solid"
                        rounded="full"
                        @click="showWatchlistDialog(props.dataItem, $event)"
                      >
                        <span class="k-icon k-i-star-outline"></span>
                      </k-button>
                    </div>
                  </td>
                </template>
              </k-grid>
              <k-button
                :disabled="disableSaveFilter"
                class="fab-filter btn-without-min-width"
                rounded="full"
                @click="downloadFilterResult"
              >
                <icon-file-arrow-down />
                <span>csv</span>
              </k-button>
            </div>
          </div>
        </li>
      </template>
    </k-panelbar>

    <k-dialog
      v-if="downloadDialog"
      :title="' '"
      @close="downloadDialog = false"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="col-12 text-center">
          <div class="title fw-bold">
            Are you sure you want to download this item?
          </div>
          <div class="fw-regular">
            CSV downloads are limited to {{ filterSizeRecord }} results.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-3 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="downloadDialog = false"
            >Close</k-button
          >
          <k-button class="me-2" theme-color="primary" @click="downloadConfirm"
            >Confirm</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="downloadSaveDialog"
      :title="' '"
      @close="downloadSaveDialog = false"
      appendTo="body"
    >
      <div class="d-flex mb-3 justify-content-center">
        <div class="col-12 text-center">
          <div class="fw-bold pb-2">Download CSV</div>
          <k-inputs
            v-model="fileNameFilter"
            placeholder="Name your CSV file"
          ></k-inputs>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <k-button
          class="me-2"
          fill-mode="outline"
          @click="downloadSaveDialog = false"
          >Close</k-button
        >
        <k-button class="me-2" theme-color="primary" @click="downloadResult"
          >Confirm</k-button
        >
      </div>
    </k-dialog>
    <!-- save Filter -->
    <k-dialog
      v-if="saveFilterDialog"
      :title="' '"
      @close="saveFilterDialog = false"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="col-12">
          <template v-if="filter.name">
            <div class="fw-bold text-center mb-3">Save this filter</div>
            <div class="row">
              <div
                class="col-12 d-flex justify-content-between align-items-center"
              >
                <span class="content">Update {{ filter.name }}</span>
                <k-button theme-color="secondary" @click="saveFilter('edit')"
                  >Update</k-button
                >
              </div>
            </div>
            <hr />
            <div class="row mb-3">
              <div class="col-12 mb-2">
                <span class="content">Create New Filter</span>
              </div>
              <div class="col-12">
                <k-inputs
                  class="custom-placeholder"
                  v-model="fileNameFilter"
                  placeholder="Filter name"
                ></k-inputs>
              </div>
              <div class="col-8">
                <k-checkbox
                  class="custom"
                  v-model="isCurrentSelection"
                  label="Include current selection"
                  :rounded="null"
                ></k-checkbox>
              </div>
              <div class="col-4 text-end">
                <k-button
                  theme-color="primary"
                  :disabled="!fileNameFilter"
                  @click="saveFilter('add')"
                  >Save Filter</k-button
                >
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-end">
                <k-button
                  fill-mode="outline"
                  @click="(saveFilterDialog = false), (fileNameFilter = null)"
                  >Cancel</k-button
                >
              </div>
            </div>
          </template>
          <template v-else>
            <div class="fw-bold text-center mb-3">New Filter</div>
            <div class="row">
              <div class="col-12">
                <k-inputs
                  class="custom-placeholder"
                  v-model="fileNameFilter"
                  placeholder="Filter name"
                ></k-inputs>
              </div>
              <div class="col-6">
                <k-checkbox
                  class="custom"
                  v-model="isCurrentSelection"
                  label="Include current selection"
                  :rounded="null"
                ></k-checkbox>
              </div>
              <div class="col-6 text-end">
                <k-button
                  class="unset-min-width me-2"
                  fill-mode="outline"
                  @click="(saveFilterDialog = false), (fileNameFilter = null)"
                  >Cancel</k-button
                >
                <k-button
                  theme-color="primary"
                  :disabled="!fileNameFilter"
                  @click="saveFilter('add')"
                  >Save Filter</k-button
                >
              </div>
            </div>
          </template>
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="confirmSaveFilterDialog"
      :title="' '"
      @close="confirmSaveFilterDialog = false"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="col-12">
          <div class="fw-bold d-flex justify-content-center align-items-center">
            <icon-warning class="text-warning me-2" /> Filter name already
            exists.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="(confirmSaveFilterDialog = false), (fileNameFilter = null)"
            >Cancel</k-button
          >
          <k-button theme-color="error" @click="confirmSaveFilter('replace')"
            >Replace</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="confirmDeleteSaveFilterDialog"
      :title="' '"
      @close="confirmDeleteSaveFilterDialog = false"
      appendTo="body"
    >
      <div class="row">
        <div class="col-12">
          <div class="title d-flex justify-content-center">
            Are you sure you want to delete the filter section, ‘{{
              selectedSavedFilters.toString().replaceAll(',', '’ / ‘')
            }}’?
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-3 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="confirmDeleteSaveFilterDialog = false"
            >Cancel</k-button
          >
          <k-button theme-color="primary" @click="deleteSavedFilter"
            >Confirm</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="saveWatchlistDialog"
      :title="' '"
      @close="(saveWatchlistDialog = false), (validWatchlistName = true)"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="col-12">
          <div class="fw-bold text-center mb-3">
            Save {{ selectedItemWatchList.name }}
            {{ selectedItemWatchList.region_name }} to watchlist
          </div>
          <hr v-if="$store.state.watchlists.length > 0" />
          <div class="row">
            <div class="col-12 custom-scrollbar" style="max-height: 30vh">
              <div
                v-for="(item, idx) in $store.state.watchlists"
                :key="idx"
                class="d-flex justify-content-between align-items-center border border-secondary rounded"
                :class="idx >= 1 ? 'mt-2' : ''"
              >
                <span class="ms-2" v-text="item.name"></span>
                <k-button
                  v-if="validateTickerWatchlist(item)"
                  type="button"
                  fill-mode="link"
                  @click="removeCompanyFromWatchlist(item)"
                  >Remove</k-button
                >
                <k-button
                  v-else
                  type="button"
                  fill-mode="link"
                  @click="saveWatchlist(item.name, true)"
                  >Add</k-button
                >
              </div>
            </div>
          </div>
          <hr v-if="$store.state.watchlists.length > 0" />

          <div class="row mb-2">
            <div class="col-12 d-flex flex-row align-items-center">
              <k-inputs
                v-model="fileNameWatchlist"
                class="custom-placeholder mb-0 me-2"
                placeholder="Create new list"
                :valid="validWatchlistName"
              ></k-inputs>
              <k-button
                theme-color="primary"
                :disabled="!fileNameWatchlist"
                @click="saveWatchlist(fileNameWatchlist, false)"
                >Save New</k-button
              >
            </div>
          </div>
          <div class="row">
            <div v-if="!validWatchlistName" class="col-6 errorfont">
              Watchlist name already exists. <br />Please use a different
              watchlist name.
            </div>
            <div v-else class="col-6"></div>
            <div class="col-6 text-end">
              <k-button
                fill-mode="outline"
                @click="
                  (saveWatchlistDialog = false),
                    (fileNameFilter = null),
                    (validWatchlistName = true)
                "
                >Close</k-button
              >
            </div>
          </div>
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="confirmDeleteWatchlistDialog"
      :title="' '"
      @close="confirmDeleteWatchlistDialog = false"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="col-12">
          <div class="fw-bold text-center">
            Are you sure you want to delete the watchlist section, ‘{{
              selectedWatchlists.toString().replaceAll(',', '’ / ‘')
            }}’?
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="confirmDeleteWatchlistDialog = false"
            >Cancel</k-button
          >
          <k-button theme-color="primary" @click="deleteWatchlist"
            >Confirm</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="watchlistNotMatchDialog"
      :title="' '"
      @close="watchlistNotMatchDialog = false"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="col-12">
          <div class="text-center"><icon-warning class="text-warning" /></div>
          <div class="fw-bold text-center">{{ watchlistMessage }}</div>
          <div class="fw-bold text-center">
            The details of the following cannot be uploaded:
          </div>
        </div>
      </div>
      <div class="row mb-3" v-if="watchlistNotMatch.length > 0">
        <div class="col-12 custom-scrollbar" style="max-height: 30vh">
          <div
            v-for="(item, idx) in watchlistNotMatch"
            :key="idx"
            :class="idx >= 1 ? 'mt-2' : ''"
          >
            <span class="ms-2" v-text="item"></span>
          </div>
        </div>
      </div>
      <div v-if="watchlistFooterMessage" class="row mb-3">
        <div class="col-12">
          <div class="fw-bold text-center">{{ watchlistFooterMessage }}</div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <k-button
          class="me-2"
          fill-mode="outline"
          @click="watchlistNotMatchDialog = false"
          >Close</k-button
        >
      </div>
    </k-dialog>
    <k-dialog
      v-if="unavailSavedFilterDialog"
      :title="' '"
      @close="(isShare = false), (unavailSavedFilterDialog = false)"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="text-center"><icon-warning class="text-warning" /></div>
        <div v-if="restrictAccessLoadFilter" class="col-12 pt-4">
          <div v-if="isShare" class="fw-bold text-center">
            You don't currently have permission to access a Region in the Share
            Filter. The filter will be not applied.
          </div>
          <div v-else class="fw-bold text-center">
            You don't currently have permission to access a Region in the Saved
            Share Filter. The filter will be not applied.
          </div>
        </div>
        <div v-if="unavailFilter.length > 0" class="col-12 pt-4">
          <div class="fw-bold text-center">
            Some data in this saved filter don't match with current dataset.
          </div>
          <div class="fw-bold text-center">The details are below:</div>
        </div>
      </div>
      <div class="row mb-3" v-if="unavailFilter.length > 0">
        <div class="col-12 custom-scrollbar" style="max-height: 30vh">
          <div
            v-for="(item, idx) in unavailFilter"
            :key="idx"
            :class="idx >= 1 ? 'mt-2' : ''"
          >
            <span class="ms-2" v-text="item"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="unavailSavedFilterDialog = false"
            >Close</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="restrictAccess"
      :title="' '"
      @close="restrictAccess = false"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="text-center"><icon-warning class="text-warning" /></div>
        <div class="col-12 pt-4">
          <!-- Updated based on Peter's suggestion and approved by the client -->
          <div class="fw-bold text-center">
            You don't currently have permission to access this
            {{ selectedRegion }} Region.
          </div>
          <div class="fw-bold text-center">
            The filter will return to your default Region.
          </div>
          <div class="fw-bold text-center">
            Click on Cancel to dismiss this message
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="restrictAccess = false"
            >Close</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="restrictAccessWatchlist"
      :title="' '"
      @close="cleanRestrictWatchlist()"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="text-center"><icon-warning class="text-warning" /></div>
        <div class="col-12 pt-4">
          <div class="fw-bold text-center">
            You don't have access to some Region(s). The details are:
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12 custom-scrollbar" style="max-height: 30vh">
          <div
            v-for="(item, idx) in replacedRegionWatchlist"
            :key="idx"
            :class="idx >= 1 ? 'mt-2' : ''"
          >
            &bull; <span class="ms-2" v-text="item"></span> is changed to the
            default region.
          </div>
          <div
            v-for="(item, idx) in blockedRegionWatchlist"
            :key="idx"
            :class="idx >= 1 ? 'mt-2' : ''"
          >
            &bull; <span class="ms-2" v-text="item"></span> is not in your list
            of Region(s).
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="cleanRestrictWatchlist()"
            >Close</k-button
          >
        </div>
      </div>
    </k-dialog>
    <!-- Region Not Found -->
    <k-dialog
      v-if="regionNotAvailable"
      :title="' '"
      @close="clearFilter()"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="text-center"><icon-warning class="text-warning" /></div>
        <div class="col-12 pt-4">
          <div class="fw-bold text-center">No Region Data Found</div>
          <div class="text-center">
            Click Cancel to dismiss this message and load default region
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button class="me-2" fill-mode="outline" @click="clearFilter()"
            >Cancel</k-button
          >
        </div>
      </div>
    </k-dialog>
    <!-- Year Not Found -->
    <k-dialog
      v-if="yearNotAvailable"
      :title="' '"
      @close="selectLatestYearAvailable()"
      appendTo="body"
    >
      <div class="row mb-3">
        <div class="text-center"><icon-warning class="text-warning" /></div>
        <div class="col-12 pt-4">
          <div class="fw-bold text-center">No Year Data Found</div>
          <div class="text-center">
            Click Cancel to dismiss this message and load default Latest Year
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="selectLatestYearAvailable()"
            >Cancel</k-button
          >
        </div>
      </div>
    </k-dialog>
    <!-- hidden input file for upload watchlist -->
    <input
      ref="watchlistFile"
      type="file"
      accept="text/csv"
      @change="uploadWatchlist"
      style="display: none"
    />

    <k-notification
      ref="popupNotification"
      :allow-hide-after="1000"
      :auto-hide-after="7000"
      :button="true"
      :hide-on-click="true"
      class="mt-2"
    >
    </k-notification>
  </div>
</template>

<script>
import axios from 'axios';
import { EventBus } from '../../main.js';
import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';
import $ from 'jquery';
import CustomMultiSelect from '../form/k-custom-multiselect';
import { ComboBox } from '@progress/kendo-vue-dropdowns';
import {
  DEFAULT_OPEN_PANEL,
  PANEL_BAR_ITEMS,
  PANEL_BAR_KEYS
} from '@/modules/PanelBar/constants';
import { MAX_SEARCH_RECORDS } from '../tenantAdmin/constants';

const DEFAULT_SKIP = 0;
const DEFAULT_TAKE = 20;

export default {
  components: {
    'k-custom-multiselect': CustomMultiSelect,
    ComboBox
  },
  data() {
    return {
      loaderImage: {
        sec: false,
        ig: false,
        aud: false,
        dom: false,
        exc: false,
        inc: false,
        sta: false,
        year: false,
        rat: false
      },
      defaultRegionId: null,
      filterSizeRecord: null,
      initFlag: true,
      showAdvFilter: true,
      advFilterText: 'Hide',
      searchKeyword: '',
      keyword: '',
      searchResponse: [],
      searchTotal: 0,
      isResponseOverloaded: false,
      selectedField: 'selected',
      selectedItem: {},
      selectedTicker: {
        year: null,
        tickerID: null,
        regionID: null,
        region_name: ''
      },
      selectedTab: 0,
      search: '',
      skip: DEFAULT_SKIP,
      take: DEFAULT_TAKE,
      pageSize: 10,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [20, 50, 100],
        previousNext: true,
        responsive: true
      },
      total: 0,
      sort: [{ field: 'final_score', dir: 'desc' }],
      riskScoreSort: 'desc',
      availableData: [],
      dataItems: [],
      columns: [
        { field: 'isin', title: 'ISIN', width: 100 },
        { field: 'name', title: 'Name', width: 150, MaxWidth: 200 },
        { field: 'domicile_country', title: 'Domicile', width: 100 },
        {
          field: 'rank_percent',
          title: 'RankPercent',
          cell: 'scoreFormat',
          width: 100
        },
        {
          field: 'rank_percent_within_sector',
          title: 'RankPerSector',
          cell: 'scoreFormat',
          width: 100
        },
        {
          field: 'rating',
          title: 'Rating',
          width: 100
        },
        {
          field: 'final_score',
          title: 'Risk',
          cell: 'scoreFormat',
          headerCell: 'yearTemplate',
          width: 110,
          headerAttributes: {
            style:
              'border-right: 1px solid #222222;border-left: 1px solid #222222;'
          }
        },
        {
          headerCell: 'actionHeaderTemplate',
          cell: 'actionTemplate',
          width: 30
        }
        //,{ field: "year", title: "Year" }
      ],
      columnWatchlists: [
        { field: 'isin', title: 'ISIN', width: 100 },
        { field: 'name', title: 'Name' },
        { field: 'domicile_country', title: 'Domicile', width: 100 },
        { field: 'region_name', title: 'Region', width: 100 },
        {
          field: 'final_score',
          title: 'Risk',
          cell: 'scoreFormat',
          width: 80
        },
        {
          field: 'rating',
          title: 'Rating',
          cell: 'scoreFormat',
          width: 80
        },
        { cell: 'actionTemplate', width: 30 }
      ],
      filter: {
        order: [],
        minRiskScore: null,
        maxRiskScore: null,
        sectorId: [],
        industryGroupId: [],
        auditorId: [],
        domicile: [],
        exchange: [],
        incorporation: [],
        status: [],
        rating: [],
        minOneYearChange: null,
        maxOneYearChange: null,
        minMarketCapDecile: null,
        maxMarketCapDecile: null,
        index: 0,
        maxResult: 100,
        pageToken: null,
        regionId: null,
        year: null,
        choosenYear: null
      },
      preFilter: {},
      year: [],
      count: {
        regionCount: null,
        riskScoreCount: null,
        sectorCount: null,
        industryCount: null,
        auditorCount: null,
        domicileCount: null,
        incorporationCount: null,
        exchangeCount: null,
        statusCount: null,
        oneYearChangeCount: null,
        marketCapDecileCount: null
      },
      riskScoreDefault: [0, 100],
      riskScore: [],
      oneYearChangeDefault: [-100, 100],
      oneYearChange: [],
      marketCapDecileDefault: [1, 10],
      marketCapDecile: [],
      regionField: null,
      region: [{ regionID: '', region_name: '' }],
      statusRegion: null,
      sector: [],
      industry: [],
      auditor: [],
      domicile: [],
      incorporation: [],
      exchange: [],
      status: [],
      rating: [],
      fileNameFilter: null,
      fileNameWatchlist: null,
      disableSaveFilter: true,
      downloadDialog: false,
      downloadSaveDialog: false,
      selectedSavedFilters: [],
      saveFilterDialog: false,
      confirmSaveFilterDialog: false,
      confirmDeleteSaveFilterDialog: false,
      isCurrentSelection: true,
      panelBar: DEFAULT_OPEN_PANEL,
      saveWatchlistDialog: false,
      validWatchlistName: true,
      watchlist: { name: null, companies: [] },
      selectedWatchlists: [],
      confirmDeleteWatchlistDialog: false,
      rerender: true,
      selectedItemWatchList: { name: '' },
      currentWatchlistName: '',
      watchlistNotMatch: [],
      watchlistMessage: '',
      watchlistFooterMessage: '',
      watchlistNotMatchDialog: false,
      unavailSavedFilterDialog: false,
      unavailFilter: [],
      restrictAccess: false,
      selectedRegion: null,
      dynamicFlag: {
        // reg: false,
        rs: false,
        sec: false,
        ig: false,
        aud: false,
        dom: false,
        exc: false,
        inc: false,
        sta: false,
        oyc: false,
        mcd: false,
        yr: false,
        rat: false
      },
      timeout: null,
      tempYear: null,
      lastSelectedYear: null,
      sliderActiveRs: false,
      sliderActiveOyc: false,
      sliderActiveMcd: false,
      restrictAccessLoadFilter: false,
      restrictAccessWatchlist: false,
      savedFilterUnmatch: false,
      replacedRegionWatchlist: new Set(),
      blockedRegionWatchlist: new Set(),
      isLoad: false,
      isShare: false,
      regionNotAvailable: false,
      yearNotAvailable: false,
      previousRating: null
    };
  },
  created() {
    this.DEFAULT_OPEN_PANEL = DEFAULT_OPEN_PANEL;
    this.PANEL_BAR_ITEMS = PANEL_BAR_ITEMS;
    this.PANEL_BAR_KEYS = PANEL_BAR_KEYS;
  },
  watch: {
    riskScore: function (newV) {
      if (
        newV[0] != this.riskScoreDefault[0] ||
        newV[1] != this.riskScoreDefault[1]
      ) {
        this.sliderActiveRs = true;
      } else {
        this.sliderActiveRs = false;
      }
    },
    oneYearChange: function (newV) {
      if (
        newV[0] != this.oneYearChangeDefault[0] ||
        newV[1] != this.oneYearChangeDefault[1]
      ) {
        this.sliderActiveOyc = true;
      } else {
        this.sliderActiveOyc = false;
      }
    },
    marketCapDecile: function (newV) {
      if (
        newV[0] != this.marketCapDecileDefault[0] ||
        newV[1] != this.marketCapDecileDefault[1]
      ) {
        this.sliderActiveMcd = true;
      } else {
        this.sliderActiveMcd = false;
      }
    },
    'selectedTicker.tickerID': function (newV) {
      if (newV) {
        this.selectedItem =
          this.dataItems.find(
            (item) =>
              item.tickerID == this.selectedTicker.tickerID &&
              item.regionID == this.selectedTicker.regionID
          ) || this.selectedItem;
      }
    },
    'filter.regionId': function (newVal, oldVal) {
      if (newVal && oldVal != null && this.isLoad == false) {
        this.checkAvailableRegion();
      }
    },
    'filter.year': function (val) {
      if (val == null) {
        EventBus.$emit('filterNotApplied', true);
      }
    },
    'filter.rating': function (val) {
      if (val) {
        this.previousRating = val;
      }
    }
  },
  mounted() {
    this.riskScore = this.riskScoreDefault;
    this.oneYearChange = this.oneYearChangeDefault;
    this.marketCapDecile = this.marketCapDecileDefault;
    if (this.$store.state.isShareUrl) {
      // action in search feature
      if (this.$store.state.shareTicker != 'null') {
        let sKey = decodeURI(this.$store.state.shareKeyword);
        if (sKey != 'null') {
          this.searchKeyword = sKey;
          this.doSearch();
        } else {
          this.searchKeyword = null;
        }
      }
    }

    // action in filter
    this.initFilter();

    this.popupNotificationWidget = this.$refs.popupNotification.kendoWidget();

    // Event save watchlist triggered from userDashboard
    EventBus.$on('saveWatchlistFromDashboard', (final_score) => {
      this.selectedItem.final_score = final_score;
      this.showWatchlistDashboardDialog();
    });

    let vm = this;
    window.onresize = function () {
      vm.rerenderRangeSlider();
    };

    window.onload = function () {
      window.dispatchEvent(new Event('resize'));
    };

    $('th[aria-sort="none"]').each(function () {
      var headerCell = $(this);
      var link = headerCell.find('span.k-link');
      var icon = link.find('span.k-i-arrows-swap');
      link.hover(
        function () {
          if (!headerCell.hasClass('k-sorted')) {
            link.append('<span class="k-icon k-i-arrows-swap"></span>');
            icon = link.find('span.k-i-arrows-swap');
          } else {
            icon.remove();
          }
        },
        function () {
          icon.remove();
        }
      );
      link.click(function () {
        icon.remove();
      });
    });

    this.removeArrowButton();
  },
  destroyed() {
    EventBus.$off('saveWatchlistFromDashboard');
  },
  computed: {
    items() {
      return this.dataItems.map((item) => ({
        ...item,
        selected: item.tickerID === this.selectedTicker.tickerID
      }));
    }
  },
  methods: {
    onChangeRiskScore(event) {
      let rs = event.value;
      this.riskScore = rs;
      this.filter.minRiskScore = rs[0];
      this.filter.maxRiskScore = rs[1];
      let min = this.filter.minRiskScore;
      let max = this.filter.maxRiskScore;
      let def = this.riskScoreDefault;
      this.loadFilterOptions('RS', this.removeFromFilterOrder(min, max, def));
    },
    onChangeOneYearChange(event) {
      let oyc = event.value;
      this.oneYearChange = oyc;
      this.filter.minOneYearChange = oyc[0];
      this.filter.maxOneYearChange = oyc[1];
      let min = this.filter.minOneYearChange;
      let max = this.filter.maxOneYearChange;
      let def = this.oneYearChangeDefault;
      this.loadFilterOptions('OYC', this.removeFromFilterOrder(min, max, def));
    },
    onChangeMarketCapDecile(event) {
      let mcd = event.value;
      this.marketCapDecile = mcd;
      this.filter.minMarketCapDecile = mcd[0];
      this.filter.maxMarketCapDecile = mcd[1];
      let min = this.filter.minMarketCapDecile;
      let max = this.filter.maxMarketCapDecile;
      let def = this.marketCapDecileDefault;
      this.loadFilterOptions('MCD', this.removeFromFilterOrder(min, max, def));
    },
    removeFromFilterOrder(min, max, def) {
      let removeFromOrder = false;
      if (min == def[0] && max == def[1]) {
        removeFromOrder = true;
      }
      return removeFromOrder;
    },
    removeArrowButton() {
      let region = document.getElementsByClassName('custom-region')[0];
      if (region) {
        let button = region.getElementsByTagName('button')[0];
        button.classList.add('d-none');
        region.addEventListener('click', function () {
          button.click();
        });
      }
      let year = document.getElementsByClassName('custom-year')[0];
      if (year) {
        let button = year.getElementsByTagName('button')[0];
        button.classList.add('d-none');
        year.addEventListener('click', function () {
          button.click();
        });
      }
    },
    onSelect: function (id) {
      //Open panel
      if (this.panelBar !== id) {
        this.panelBar = id;
      }

      if (this.panelBar === this.PANEL_BAR_KEYS.FILTERS) {
        window.dispatchEvent(new Event('resize'));
      }
    },
    onClickPanelArrow: function (e, id) {
      e.stopPropagation();

      //Click on arrow of currently opened panel => Close all and open default panel
      if (this.panelBar === id) {
        this.panelBar = DEFAULT_OPEN_PANEL;
      } else {
        // Else open clicked panel
        this.panelBar = id;
      }

      if (this.panelBar === this.PANEL_BAR_KEYS.FILTERS) {
        window.dispatchEvent(new Event('resize'));
      }
    },
    sortRisk() {
      if (this.riskScoreSort === 'desc') {
        this.riskScoreSort = null;
        this.sort = this.sort.filter((t) => t.field !== 'final_score');
        return;
      }

      this.riskScoreSort = this.riskScoreSort === 'asc' ? 'desc' : 'asc';

      (this.sort.find((t) => t.field === 'final_score') || {}).dir =
        this.riskScoreSort;

      this.applyFilter();
    },
    pageChangeHandler(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
      this.applyFilter();
    },
    viewTickerDetail(selectedTicker, selectedRegion) {
      this.filter.selectedID = selectedTicker.tickerID;
      this.$store.commit('fillShareFilter', this.filter);
      this.$store.commit('fillShareTicker', selectedTicker.tickerID);
      this.$store.commit('fillShareRegion', selectedTicker.regionID);

      let ticker = {};
      ticker.id = selectedTicker.tickerID;
      ticker.region = selectedTicker.regionID;
      ticker.year = selectedTicker.year;

      ticker.available = true;
      // ticker.filterAlreadyApplied = true;
      let regionList = this.region.map((reg) => parseInt(reg.regionID));
      let result = regionList.includes(parseInt(selectedTicker.regionID));
      if (!result) {
        ticker.available = false;
      }
      // if (this.filter.year == null) {
      // 	ticker.filterAlreadyApplied = false;
      // }

      if (!selectedRegion) {
        let selectRegion = this.region.find(
          (reg) => reg.regionID == this.filter.regionId
        );
        ticker.selectedRegion =
          selectRegion && selectRegion.region_name
            ? selectRegion.region_name
            : null;
      } else {
        ticker.selectedRegion = selectedRegion;
      }
      this.selectedTicker.region_name = ticker.selectedRegion;
      this.$store.commit('fillShareRegionName', ticker.selectedRegion);
      EventBus.$emit('ticker', ticker);
    },
    doSearch() {
      if (this.searchKeyword.length > 0) {
        axios
          .post('/dashboard/search', {
            keywords: this.searchKeyword.toUpperCase(),
            maxResult: MAX_SEARCH_RECORDS,
            index: 0,
            pageToken: ''
          })
          .then((response) => {
            this.searchResponse = [];
            this.isResponseOverloaded = false;
            this.keyword = this.searchKeyword;
            this.searchResponse = [...response.data.data];
            this.searchTotal = this.searchResponse.length;

            if (response.data.total > MAX_SEARCH_RECORDS) {
              this.searchResponse = [];
              this.searchTotal = 0;
              this.isResponseOverloaded = true;
            }
            // remove duplicate object from Big Query's result
            // this.searchResponse = this.searchResponse.filter((v, i, a) => a.findIndex((v2) => v2.tickerID === v.tickerID) === i);
            // this.searchTotal = this.searchResponse.length;

            this.$store.commit('fillShareKeyword', this.keyword);
          });
      } else {
        this.searchResponse = [];
        this.searchTotal = 0;
        this.keyword = '';
      }
    },
    sortChangeHandler(e) {
      this.sort = e.sort;

      if (e.event.field === 'final_score') {
        this.sortRisk();
        return;
      }

      this.applyFilter();
    },
    onResultClick(item) {
      this.selectedTicker.tickerID = item.tickerID;
      this.selectedTicker.regionID = item.regionID;
      // Don't set the year in selectedTicker, use last available year for stock from search result
      this.selectedTicker.year = null;
      this.selectedItem = item;
      this.viewTickerDetail(this.selectedTicker, null);
    },
    onRowClick(item) {
      this.selectedTicker.tickerID = item.dataItem.tickerID;
      this.selectedTicker.regionID = item.dataItem.regionID;
      this.selectedTicker.year = this.filter.year;
      this.viewTickerDetail(this.selectedTicker, null);
    },
    onRowClickWatchlist(item) {
      this.selectedTicker.tickerID = item.dataItem.tickerID;
      this.selectedTicker.regionID = item.dataItem.regionID;
      // Don't set the year in selected watchlist, use last available year for stock
      this.selectedTicker.year = null;
      this.selectedItem = item.dataItem;
      this.viewTickerDetail(this.selectedTicker, null);
    },
    onSelectTab(e) {
      this.selectedTab = e;
      if (this.selectedTab == 0) {
        //this.rerenderRangeSlider();
        window.dispatchEvent(new Event('resize'));
      }
    },
    advFilter() {
      if (this.advFilterText == 'Show') {
        this.advFilterText = 'Hide';
        this.showAdvFilter = true;
        document
          .getElementById('gridFilter')
          .classList.remove('grid-filter-max');
        document.getElementById('gridFilter').classList.add('grid-filter-min');
        window.dispatchEvent(new Event('resize'));
      } else if (this.advFilterText == 'Hide') {
        this.advFilterText = 'Show';
        this.showAdvFilter = false;
        document
          .getElementById('gridFilter')
          .classList.remove('grid-filter-min');
        document.getElementById('gridFilter').classList.add('grid-filter-max');
        window.dispatchEvent(new Event('resize'));
      }
    },

    // ---------------
    checkAvailableRegion() {
      let selected = parseInt(this.filter.regionId);
      let regionList = this.region.map((reg) => parseInt(reg.regionID));
      let result = regionList.includes(selected);
      if (result) {
        this.loadFilterOptions('REG');
      } else {
        this.regionNotAvailable = !result;
      }
    },
    checkAvailableYear() {
      this.skip = 0;
      this.applyFilter();
    },
    selectLatestYearAvailable() {
      this.yearNotAvailable = false;
      this.tempYear = this.year[0];
    },
    applyFilter() {
      // conversion string to int array for sector and industry
      this.selectedRegion = '';
      this.filter.year = this.tempYear;

      if (this.filter.sectorId.length > 0) {
        this.filter.sectorId = Object.assign(
          [],
          this.filter.sectorId.map((i) => Number(i))
        );
      }

      if (this.filter.industryGroupId.length > 0) {
        this.filter.industryGroupId = Object.assign(
          [],
          this.filter.industryGroupId.map((i) => Number(i))
        );
      }

      if (!this.initFlag) {
        if (!this.filter.regionId) {
          this.statusRegion = 'Region is required';
          return;
        } else {
          let selectRegion = this.region.find(
            (reg) => reg.regionID === +this.filter.regionId
          );
          this.selectedRegion = selectRegion.region_name;
          this.statusRegion = null;
        }

        if (
          this.riskScore[0] !== this.riskScoreDefault[0] ||
          this.riskScore[1] !== this.riskScoreDefault[1]
        ) {
          this.filter.minRiskScore = this.riskScore[0];
          this.filter.maxRiskScore = this.riskScore[1];
        } else {
          this.filter.minRiskScore = null;
          this.filter.maxRiskScore = null;
        }

        if (
          this.oneYearChange[0] !== this.oneYearChangeDefault[0] ||
          this.oneYearChange[1] !== this.oneYearChangeDefault[1]
        ) {
          this.filter.minOneYearChange = this.oneYearChange[0];
          this.filter.maxOneYearChange = this.oneYearChange[1];
        } else {
          this.filter.minOneYearChange = null;
          this.filter.maxOneYearChange = null;
        }

        if (
          this.marketCapDecile[0] !== this.marketCapDecileDefault[0] ||
          this.marketCapDecile[1] !== this.marketCapDecileDefault[1]
        ) {
          this.filter.minMarketCapDecile = this.marketCapDecile[0];
          this.filter.maxMarketCapDecile = this.marketCapDecile[1];
        } else {
          this.filter.minMarketCapDecile = null;
          this.filter.maxMarketCapDecile = null;
        }
      }

      this.filter.pageSkip = this.skip;
      this.filter.pageTake = this.take;
      const filterOrders = [...(this.sort || [])];

      if (!filterOrders.some((t) => t.field === 'final_score')) {
        const fieldOrder = {
          field: 'final_score',
          dir: this.riskScoreSort || 'desc'
        };
        filterOrders.push(fieldOrder);

        if (this.riskScoreSort) {
          this.sort.push(fieldOrder);
        }
      }

      this.filter.filterOrders = filterOrders;

      axios
        .post('/dashboard/filter', this.filter)
        .then((response) => {
          this.dataItems = response.data.data;
          this.total = response.data.total;

          if (response.data.restrictAccess) {
            this.restrictAccess = true;
            this.fetchRegion();
          }
        })
        .then(() => {
          this.$nextTick(() => {
            this.disableSaveFilter = this.dataItems.length <= 0;
            this.filter.riskScore = this.$root.clone(this.riskScore);
            this.filter.oneYearChange = this.$root.clone(this.oneYearChange);
            this.filter.marketCapDecile = this.$root.clone(
              this.marketCapDecile
            );
            // Select Default year by User
            this.filter.lastSelectedYear = this.lastSelectedYear;
            this.$store.commit('fillShareFilter', this.filter);

            if (this.initFlag && this.dataItems.length > 0) {
              this.initFlag = false;

              if (!this.$store.state.isShareUrl) {
                this.selectedTicker.tickerID = this.dataItems[0].tickerID;
                this.selectedTicker.regionID = this.dataItems[0].regionID;
                this.selectedTicker.region_name = this.dataItems[0].region_name;
                this.selectedTicker.year = this.filter.year || this.tempYear;
                this.viewTickerDetail(
                  this.selectedTicker,
                  this.selectedTicker.region_name
                );
              }
            }
          });
        });

      if (!this.initFlag) {
        axios.post('/dashboard/filterCount', this.filter).then((response) => {
          this.count = response.data;
        });
      }

      EventBus.$emit('filterNotApplied', false);
    },
    // ---------------
    showSaveFilterDialog() {
      if (!this.filter.regionId) {
        this.statusRegion = 'Region is required';
        return;
      } else {
        this.saveFilterDialog = true;
      }
    },
    saveFilter(actionType) {
      this.filter.riskScore = this.$root.clone(this.riskScore);
      this.filter.oneYearChange = this.$root.clone(this.oneYearChange);
      this.filter.marketCapDecile = this.$root.clone(this.marketCapDecile);
      if (actionType == 'add') {
        let index = this.$store.state.filters.findIndex(
          (x) => x.name == this.fileNameFilter
        );
        if (index >= 0) {
          this.confirmSaveFilterDialog = true;
        } else {
          this.confirmSaveFilter(actionType);
        }
      } else {
        this.confirmSaveFilter(actionType);
      }
      this.saveFilterDialog = false;
    },
    confirmSaveFilter(actionType) {
      // handling for reference to previous saved filter data
      let saveFilter = this.$root.clone(this.filter);

      saveFilter.year = this.tempYear;
      // save selected year by user
      saveFilter.lastSelectedYear = this.lastSelectedYear || null;
      if (
        this.fileNameFilter &&
        (actionType == 'add' || actionType == 'replace')
      ) {
        saveFilter.name = this.$root.clone(this.fileNameFilter);
      }
      if (this.isCurrentSelection) {
        saveFilter.selectedID = this.selectedTicker.tickerID;
        saveFilter.selectedRegionID = this.selectedTicker.regionID;
        saveFilter.selectedRegion = this.selectedTicker.region_name;
        saveFilter.choosenYear = this.$store.state.shareYear;
      } else {
        saveFilter.selectedID = null;
        saveFilter.selectedRegionID = null;
        saveFilter.selectedRegion = null;
        saveFilter.choosenYear = null;
      }
      this.$store.commit('saveFilter', saveFilter);
      this.fileNameFilter = null;
      this.confirmSaveFilterDialog = false;
      // set new saved filter to current filter
      this.filter = this.$root.clone(saveFilter);
    },
    // ---------------
    async loadSavedFilter(selectedFilter) {
      // deep copy
      let load = JSON.parse(JSON.stringify(selectedFilter));
      let filtered = JSON.parse(JSON.stringify(selectedFilter));
      if (!filtered.order) {
        return;
      }
      this.resetDynamicFlag();
      this.restrictAccessLoadFilter = false;
      this.unavailFilter = [];
      let unavailData = [];
      let counter = 0;
      let filtertmp = {
        order: [],
        minRiskScore: null,
        maxRiskScore: null,
        sectorId: [],
        industryGroupId: [],
        auditorId: [],
        domicile: [],
        exchange: [],
        incorporation: [],
        status: [],
        rating: [],
        minOneYearChange: null,
        maxOneYearChange: null,
        minMarketCapDecile: null,
        maxMarketCapDecile: null,
        regionId: null,
        year: null,
        riskScore: [],
        marketCapDecile: [],
        oneYearChange: []
      };

      load.auditorId = load.auditorId == null ? [] : load.auditorId;
      load.domicile = load.domicile == null ? [] : load.domicile;
      load.exchange = load.exchange == null ? [] : load.exchange;
      load.incorporation = load.incorporation == null ? [] : load.incorporation;
      load.industryGroupId =
        load.industryGroupId == null ? [] : load.industryGroupId;
      load.sectorId = load.sectorId == null ? [] : load.sectorId;
      load.status = load.status == null ? [] : load.status;
      load.rating = load.rating == null ? [] : load.rating;

      //add year order so year will fetch at the end
      filtered.order.push('YEAR');
      filtered.order.push('RAT');

      //for(const order of filtered.order){
      for (let index = 0; index < filtered.order.length; index++) {
        if (this.restrictAccessLoadFilter == true) {
          continue;
        }
        if (filtered.order[index] == 'REG' && filtered.regionId) {
          this.clearDataFilter();
          this.region = [];
          filtertmp.order.push('REG');

          await axios
            .post('/dashboard/region')
            .then((response) => {
              this.region = response.data;
              if (!response.data.some((e) => e.regionID == load.regionId)) {
                filtered.regionId = response.data[0].regionID;
                this.restrictAccessLoadFilter = true;
              }
            })
            .then(() => {
              filtertmp.regionId = this.$root.clone(filtered.regionId);
            });
        } else if (filtered.order[index] == 'RS') {
          this.clearDataFilter();
          this.dynamicFlag.rs = true;
          filtertmp.order.push('RS');

          await axios
            .post('/dashboard/riskScore', filtertmp)
            .then((response) => {
              this.riskScoreDefault = [
                parseFloat(Number(response.data[0].min).toFixed(2)),
                parseFloat(Number(response.data[0].max).toFixed(2))
              ];
              this.riskScore = this.$root.clone(this.riskScoreDefault);
              unavailData = [];
              counter = 0;
              if (!(this.riskScoreDefault[0] <= load.riskScore[0])) {
                this.unavailFilter.push(
                  'Min Risk Score : ' +
                    load.riskScore[0] +
                    ' will be changed to ' +
                    this.riskScoreDefault[0]
                );
                filtered.riskScore[0] = this.riskScoreDefault[0];
                filtered.minRiskScore = this.riskScoreDefault[0];
              }
              if (!(this.riskScoreDefault[1] >= load.riskScore[1])) {
                this.unavailFilter.push(
                  'Max Risk Score : ' +
                    load.riskScore[1] +
                    ' will be changed to ' +
                    this.riskScoreDefault[1]
                );
                filtered.riskScore[1] = this.riskScoreDefault[1];
                filtered.maxRiskScore = this.riskScoreDefault[1];
              }
            })
            .then(() => {
              filtertmp.minRiskScore = this.$root.clone(filtered.minRiskScore);
              filtertmp.maxRiskScore = this.$root.clone(filtered.maxRiskScore);
              filtertmp.riskScore = this.$root.clone(filtered.riskScore);
            });
        } else if (filtered.order[index] == 'SEC' && filtered.sectorId) {
          this.clearDataFilter();
          this.dynamicFlag.sec = true;
          filtertmp.order.push('SEC');

          await axios
            .post('/dashboard/sector', filtertmp)
            .then((response) => {
              this.sector = response.data;
              unavailData = [];
              counter = 0;
              for (let x = 0; x < load.sectorId.length; x++) {
                if (
                  !response.data.some((e) => e.sectorID == load.sectorId[x])
                ) {
                  unavailData[counter] = load.sectorId[x];
                  counter++;
                  filtered.sectorId = filtered.sectorId.filter(
                    (data) => data != load.sectorId[x]
                  );
                }
              }
              if (unavailData.length > 0)
                this.unavailFilter.push(
                  'Sector ID ' +
                    unavailData +
                    ' will not be included in the filter'
                );
            })
            .then(() => {
              filtertmp.sectorId = this.$root.clone(filtered.sectorId);
            });
        } else if (filtered.order[index] == 'IG' && filtered.industryGroupId) {
          this.clearDataFilter();
          this.dynamicFlag.ig = true;
          filtertmp.order.push('IG');

          await axios
            .post('/dashboard/industry', filtertmp)
            .then((response) => {
              this.industry = response.data;
              unavailData = [];
              counter = 0;
              for (let x = 0; x < load.industryGroupId.length; x++) {
                if (
                  !response.data.some(
                    (e) => e.industry_groupID == load.industryGroupId[x]
                  )
                ) {
                  unavailData[counter] = load.industryGroupId[x];
                  counter++;
                  filtered.industryGroupId = filtered.industryGroupId.filter(
                    (data) => data != load.industryGroupId[x]
                  );
                }
              }
              if (unavailData.length > 0)
                this.unavailFilter.push(
                  'Industry Group ID ' +
                    unavailData +
                    ' will not be included in the filter'
                );
            })
            .then(() => {
              filtertmp.industryGroupId = this.$root.clone(
                filtered.industryGroupId
              );
            });
        } else if (filtered.order[index] == 'AUD' && filtered.auditorId) {
          this.clearDataFilter();
          this.dynamicFlag.aud = true;
          filtertmp.order.push('AUD');

          await axios
            .post('/dashboard/auditor', filtertmp)
            .then((response) => {
              this.auditor = response.data;
              unavailData = [];
              counter = 0;
              for (let x = 0; x < load.auditorId.length; x++) {
                if (
                  !response.data.some((e) => e.auditorID == load.auditorId[x])
                ) {
                  unavailData[counter] = load.auditorId[x];
                  counter++;
                  filtered.auditorId = filtered.auditorId.filter(
                    (data) => data != load.auditorId[x]
                  );
                }
              }
              if (unavailData.length > 0)
                this.unavailFilter.push(
                  'Auditor ID ' +
                    unavailData +
                    ' will not be included in the filter'
                );
            })
            .then(() => {
              filtertmp.auditorId = this.$root.clone(filtered.auditorId);
            });
        } else if (filtered.order[index] == 'DOM' && filtered.domicile) {
          this.clearDataFilter();
          this.dynamicFlag.dom = true;
          filtertmp.order.push('DOM');

          await axios
            .post('/dashboard/domicile', filtertmp)
            .then((response) => {
              this.domicile = response.data;
              unavailData = [];
              counter = 0;
              for (let x = 0; x < load.domicile.length; x++) {
                if (
                  !response.data.some(
                    (e) => e.domicile_country == load.domicile[x]
                  )
                ) {
                  unavailData[counter] = load.domicile[x];
                  counter++;
                  filtered.domicile = filtered.domicile.filter(
                    (data) => data != load.domicile[x]
                  );
                }
              }
              if (unavailData.length > 0)
                this.unavailFilter.push(
                  'Domicile ID  ' +
                    unavailData +
                    ' will not be included in the filter'
                );
            })
            .then(() => {
              filtertmp.domicile = this.$root.clone(filtered.domicile);
            });
        } else if (filtered.order[index] == 'EXC' && filtered.exchange) {
          this.clearDataFilter();
          this.dynamicFlag.exc = true;
          filtertmp.order.push('EXC');

          await axios
            .post('/dashboard/exchange', filtertmp)
            .then((response) => {
              this.exchange = response.data;
              unavailData = [];
              counter = 0;
              for (let x = 0; x < load.exchange.length; x++) {
                if (
                  !response.data.some(
                    (e) => e.exchange_country == load.exchange[x]
                  )
                ) {
                  unavailData[counter] = load.exchange[x];
                  counter++;
                  filtered.exchange = filtered.exchange.filter(
                    (data) => data != load.exchange[x]
                  );
                }
              }
              if (unavailData.length > 0)
                this.unavailFilter.push(
                  'Exchange ID ' +
                    unavailData +
                    ' will not be included in the filter'
                );
            })
            .then(() => {
              filtertmp.exchange = this.$root.clone(filtered.exchange);
            });
        } else if (filtered.order[index] == 'INC' && filtered.incorporation) {
          this.clearDataFilter();
          this.dynamicFlag.inc = true;
          filtertmp.order.push('INC');

          await axios
            .post('/dashboard/incorporation', filtertmp)
            .then((response) => {
              this.incorporation = response.data;
              unavailData = [];
              counter = 0;
              for (let x = 0; x < load.incorporation.length; x++) {
                if (
                  !response.data.some(
                    (e) => e.incorporation_country == load.incorporation[x]
                  )
                ) {
                  unavailData[counter] = load.incorporation[x];
                  counter++;
                  filtered.incorporation = filtered.incorporation.filter(
                    (data) => data != load.incorporation[x]
                  );
                }
              }
              if (unavailData.length > 0)
                this.unavailFilter.push(
                  'Incorporation ID ' +
                    unavailData +
                    ' will not be included in the filter'
                );
            })
            .then(() => {
              filtertmp.incorporation = this.$root.clone(
                filtered.incorporation
              );
            });
        } else if (filtered.order[index] == 'STA' && filtered.status) {
          this.clearDataFilter();
          this.dynamicFlag.sta = true;
          filtertmp.order.push('STA');

          await axios.post('/dashboard/status', filtertmp).then((response) => {
            this.status = response.data;
            unavailData = [];
            counter = 0;
            for (let x = 0; x < load.status.length; x++) {
              if (
                !response.data.some((e) => e.trading_status == load.status[x])
              ) {
                unavailData[counter] = load.status[x];
                counter++;
                filtered.status = filtered.status.filter(
                  (data) => data != load.status[x]
                );
              }
            }
            if (unavailData.length > 0)
              this.unavailFilter.push(
                'Status ' + unavailData + ' will not be included in the filter'
              );
          });
        } else if (filtered.order[index] == 'OYC') {
          this.clearDataFilter();
          this.dynamicFlag.oyc = true;
          filtertmp.order.push('OYC');

          await axios
            .post('/dashboard/oneYearChange', filtertmp)
            .then((response) => {
              this.oneYearChangeDefault = [
                Math.round(Number(response.data[0].min)),
                Math.round(Number(response.data[0].max))
              ];
              this.oneYearChange = this.$root.clone(this.oneYearChangeDefault);

              if (!(this.oneYearChangeDefault[0] <= load.oneYearChange[0])) {
                this.unavailFilter.push(
                  'Min 1 year change : ' +
                    load.oneYearChange[0] +
                    ' will be changed to ' +
                    this.oneYearChangeDefault[0]
                );
                filtered.oneYearChange[0] = this.oneYearChangeDefault[0];
                filtered.minOneYearChange = this.oneYearChangeDefault[0];
              }
              if (!(this.oneYearChangeDefault[1] >= load.oneYearChange[1])) {
                this.unavailFilter.push(
                  'Max 1 year change : ' +
                    load.oneYearChange[1] +
                    ' will be changed to ' +
                    this.oneYearChangeDefault[1]
                );
                filtered.oneYearChange[1] = this.oneYearChangeDefault[1];
                filtered.maxOneYearChange = this.oneYearChangeDefault[1];
              }
            })
            .then(() => {
              filtertmp.minOneYearChange = this.$root.clone(
                filtered.minOneYearChange
              );
              filtertmp.maxOneYearChange = this.$root.clone(
                filtered.maxOneYearChange
              );
              filtertmp.oneYearChange = this.$root.clone(
                filtered.oneYearChange
              );
            });
        } else if (filtered.order[index] == 'MCD') {
          this.clearDataFilter();
          this.dynamicFlag.mcd = true;
          filtertmp.order.push('MCD');

          await axios
            .post('/dashboard/marketCD', filtertmp)
            .then((response) => {
              this.marketCapDecileDefault = [
                Number(response.data[0].min),
                Number(response.data[0].max)
              ];
              this.marketCapDecile = this.$root.clone(
                this.marketCapDecileDefault
              );

              if (
                !(this.marketCapDecileDefault[0] <= load.marketCapDecile[0])
              ) {
                this.unavailFilter.push(
                  'Min Market Cap Decile : ' +
                    load.marketCapDecile[0] +
                    ' will be changed to ' +
                    this.marketCapDecileDefault[0]
                );
                filtered.marketCapDecile[0] = this.marketCapDecileDefault[0];
                filtered.minMarketCapDecile = this.marketCapDecileDefault[0];
              }
              if (
                !(this.marketCapDecileDefault[1] >= load.marketCapDecile[1])
              ) {
                this.unavailFilter.push(
                  'Max Market Cap Decile : ' +
                    load.marketCapDecile[1] +
                    ' will be changed to ' +
                    this.marketCapDecileDefault[1]
                );
                filtered.marketCapDecile[1] = this.marketCapDecileDefault[1];
                filtered.maxMarketCapDecile = this.marketCapDecileDefault[1];
              }
            })
            .then(() => {
              filtertmp.minMarketCapDecile = this.$root.clone(
                filtered.minMarketCapDecile
              );
              filtertmp.maxMarketCapDecile = this.$root.clone(
                filtered.maxMarketCapDecile
              );
              filtertmp.marketCapDecile = this.$root.clone(
                filtered.marketCapDecile
              );
            });
        } else if (filtered.order[index] == 'YEAR' && filtered.year) {
          this.year = [];
          await axios
            .post('/dashboard/year', filtertmp)
            .then((response) => {
              this.year = response.data;
              if (!response.data.includes(load.year)) {
                this.unavailFilter.push(
                  'Year : ' + load.year + ' will be changed to ' + this.year[0]
                );
                filtered.year = this.year[0];
              }
            })
            .then(() => {
              this.tempYear = this.$root.clone(filtered.year);
            });
        } else if (filtered.order[index] == 'RAT' && filtered.rating) {
          this.clearDataFilter();
          this.dynamicFlag.aud = true;
          filtertmp.order.push('AUD');

          this.loaderImage.rat = true;

          await axios
            .post('/dashboard/rating', filtertmp)
            .then((response) => {
              this.rating = response.data.sort((x, y) => {
                if (x.rating < y.rating) {
                  return -1;
                }
                if (x.rating > y.rating) {
                  return 1;
                }
                return 0;
              });
              unavailData = [];
              counter = 0;
              for (let x = 0; x < load.rating.length; x++) {
                if (!response.data.some((e) => e == load.rating[x])) {
                  unavailData[counter] = load.rating[x];
                  counter++;
                  filtered.rating = filtered.rating.filter(
                    (data) => data != load.rating[x]
                  );
                }
              }
              if (unavailData.length > 0)
                this.unavailFilter.push(
                  'Rating ' +
                    unavailData +
                    ' will not be included in the filter'
                );
            })
            .then(() => {
              filtertmp.rating = this.$root.clone(filtered.rating);
            });
        }
      }
      if (this.unavailFilter.length > 0 || this.restrictAccessLoadFilter) {
        this.unavailSavedFilterDialog = true;
      }
      // Apply selected year
      this.lastSelectedYear = filtered.lastSelectedYear;
      this.tempYear = filtered.year;
      this.loadFilter(filtered);

      await this.loadUnsellectFilterOptions();
      this.$nextTick(() => {
        if (!this.restrictAccessLoadFilter) {
          this.applyFilter();
        } else {
          EventBus.$emit('filterNotApplied', true);
          this.dataItems = [];
          this.count = {};
        }
        window.dispatchEvent(new Event('resize'));
      });
    },
    // ---------------

    loadFilter(selectedFilter) {
      this.isLoad = true;
      this.filter = this.$root.clone(selectedFilter);
      this.$nextTick(() => {
        this.isLoad = false;
        this.riskScore = this.$root.clone(selectedFilter.riskScore);
        this.oneYearChange = this.$root.clone(selectedFilter.oneYearChange);
        this.marketCapDecile = this.$root.clone(selectedFilter.marketCapDecile);
        if (selectedFilter.selectedID) {
          this.selectedTicker.tickerID = selectedFilter.selectedID;
          this.selectedTicker.regionID = selectedFilter.selectedRegionID;
          this.selectedTicker.selectedRegion = selectedFilter.selectedRegion;
          this.selectedTicker.year =
            selectedFilter.choosenYear == null
              ? selectedFilter.year
              : selectedFilter.choosenYear;
          this.viewTickerDetail(
            this.selectedTicker,
            this.selectedTicker.selectedRegion
          );
        }
        this.selectedTab = 0;
      });
    },
    deleteSavedFilter() {
      for (let selectedSavedFilter of this.selectedSavedFilters) {
        this.$store.commit('deleteFilter', selectedSavedFilter);
        if (this.filter.name == selectedSavedFilter)
          this.filter.name = undefined;
      }
      this.selectedSavedFilters = [];
      this.confirmDeleteSaveFilterDialog = false;
    },
    showWatchlistDashboardDialog() {
      this.selectedItemWatchList = this.$root.clone(this.selectedItem);
      this.fileNameWatchlist = null;
      this.saveWatchlistDialog = true;
    },
    showWatchlistDialog(item, e) {
      if (e) e.stopPropagation();

      if (item) {
        this.selectedItemWatchList = this.$root.clone(item);
      }

      this.fileNameWatchlist = null;
      this.saveWatchlistDialog = true;
    },
    saveWatchlist(name, isUpdate) {
      let watchlistTemp = { name: null, companies: [] };
      if (isUpdate) {
        watchlistTemp = this.$root.clone(
          this.$store.state.watchlists.find((x) => x.name == name)
        );
        watchlistTemp.companies.push(
          this.$root.clone(this.selectedItemWatchList)
        );
      } else {
        let isExist = this.$store.state.watchlists.find((x) => x.name == name);
        if (isExist) {
          this.validWatchlistName = false;
          return;
        }
        watchlistTemp.name = name;
        watchlistTemp.companies.push(
          this.$root.clone(this.selectedItemWatchList)
        );
        this.saveWatchlistDialog = false;
      }
      this.$store.commit('saveWatchlist', watchlistTemp);
      this.refreshWatchlist();
      this.fileNameWatchlist = null;
    },
    loadWatchlist(selectedWatchlist) {
      let prewatchlist = this.$root.clone(selectedWatchlist);

      let validRegions = [];
      let promises = [];

      let allowedRegion = [];

      // get allowed regions for the user
      axios.post('/dashboard/region').then((response) => {
        allowedRegion = [...response.data];

        // check selected watchlist against allowed regions
        prewatchlist.companies.forEach((comp) => {
          if (
            allowedRegion.some(
              (reg) =>
                reg.regionID == comp.regionID &&
                reg.region_name == comp.region_name
            )
          ) {
            comp.partof = true;
          } else {
            //	if region is not part of allowed regions then get ticker's regionID
            promises.push(
              axios.post('/dashboard/regionsByTickerId', {
                tickerId: comp.tickerID
              })
            );
            comp.partof = false;
          }
          validRegions.push(comp);
        });

        // this.watchlist = {...prewatchlist};
        // this.watchlist.companies = [...validRegions];
        // this.currentWatchlistName = this.watchlist.name;

        Promise.all(promises).then((result) => {
          const defaultRegions = [];

          result.map((res) => {
            let found = false;
            //	check ticker's regionID against allowed regionID
            for (let i = 0; i < res.data.length && !found; i++) {
              let region = [];
              region = allowedRegion.filter((reg) => {
                return (
                  reg.regionID == res.data[i].regionID &&
                  reg.region_name == res.data[i].region_name
                );
              });
              if (region.length > 0) {
                // need to push explicitly since regionID, region_name and region_description may have same value with existing element in defaultRegions
                defaultRegions.push({
                  regionID: region[0].regionID,
                  region_name: region[0].region_name,
                  region_description: region[0].region_description,
                  tickerID: res.data[i].tickerID
                });

                found = true;
              }
            }
          });

          defaultRegions.map((dr) => {
            validRegions.map((vr) => {
              if (vr.tickerID == dr.tickerID) {
                // invoke restrict watchlist dialog for ticker with changed region
                this.replacedRegionWatchlist.add(vr.region_name);
                this.restrictAccessWatchlist = true;

                vr.regionID = dr.regionID;
                vr.region_name = dr.region_name;
                vr.partof = true;
              }
            });
          });

          // invoke restrict watchlist dialog for ticker with blocked region
          validRegions.map((val) => {
            if (val.partof == false) {
              this.blockedRegionWatchlist.add(val.region_name);
              this.restrictAccessWatchlist = true;
            }
          });

          // remove ticker where it's region blocked from the list
          validRegions = validRegions.filter((val) => {
            return val.partof == true;
          });

          this.watchlist = { ...prewatchlist };
          this.watchlist.companies = [...validRegions];
          this.currentWatchlistName = this.watchlist.name;
        });
      });
    },
    cleanRestrictWatchlist() {
      this.restrictAccessWatchlist = false;
      this.replacedRegionWatchlist = new Set();
      this.blockedRegionWatchlist = new Set();
    },
    refreshWatchlist() {
      if (this.currentWatchlistName) {
        let watchlistTemp = this.$store.state.watchlists.find(
          (x) => x.name == this.currentWatchlistName
        );
        if (watchlistTemp) {
          this.watchlist = this.$root.clone(watchlistTemp);
        }
      }
    },
    validateTickerWatchlist(item) {
      return item.companies.find(
        (x) =>
          x.tickerID == this.selectedItemWatchList.tickerID &&
          x.domicile_country == this.selectedItemWatchList.domicile_country &&
          x.regionID == this.selectedItemWatchList.regionID
      );
    },
    removeCompanyFromWatchlist(watchlist) {
      let index = watchlist.companies.findIndex(
        (x) =>
          x.isin == this.selectedItemWatchList.isin &&
          x.tickerID == this.selectedItemWatchList.tickerID &&
          x.regionID == this.selectedItemWatchList.regionID
      );
      if (index >= 0) {
        watchlist.companies.splice(index, 1);
        this.$store.commit('saveWatchlist', watchlist);
        this.refreshWatchlist();
      }
    },
    downloadWatchlist() {
      let watchlists = [];
      let filename = '';
      // Header
      watchlists.push([
        'isin',
        'company_name',
        'domicile_country',
        'region_id',
        'region_name',
        'risk_score',
        'watchlist_name'
      ]);
      for (let selectedWatchlist of this.selectedWatchlists) {
        let item = this.$store.state.watchlists.find(
          (x) => x.name == selectedWatchlist
        );
        if (item) {
          if (item.companies.length > 0) {
            for (let company of item.companies) {
              watchlists.push([
                company.isin,
                company.name,
                company.domicile_country,
                company.regionID,
                company.region_name,
                company.final_score,
                item.name
              ]);
            }
          } else {
            watchlists.push([null, null, null, null, null, null, item.name]);
          }
          filename = item.name;
        }
      }
      let csvContent =
        'data:text/csv;charset=utf-8,' +
        watchlists.map((e) => e.join(',')).join('\n');
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', filename + '.csv');
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "filename.csv".
    },
    deleteWatchlist() {
      for (let selectedWatchlist of this.selectedWatchlists) {
        this.$store.commit('deleteWatchlist', selectedWatchlist);
        if (this.watchlist.name && this.watchlist.name == selectedWatchlist) {
          this.watchlist = { name: null, companies: [] };
        }
      }
      this.selectedWatchlists = [];
      this.confirmDeleteWatchlistDialog = false;
    },
    uploadWatchlist(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      if (file.name && file.name.split('.').pop() == 'csv') {
        let fileName = file.name.split('.')[0];
        var vm = this;
        const promises = [];
        this.watchlistNotMatch = [];
        reader.onload = (e) => {
          const data = vm.csvToArray(e.target.result);
          for (let item of data) {
            promises.push(
              new Promise((resolve) => {
                // Handling empty row
                if (item.isin) {
                  let regionIdTemp = item.region_id ? item.region_id : null;
                  axios
                    .post('/dashboard/isin', {
                      isin: item.isin,
                      regionId: parseInt(regionIdTemp)
                    })
                    .then((response) => {
                      if (
                        response.status != 200 ||
                        !response.data ||
                        response.data.isin != item.isin ||
                        !response.data.tickerID
                      ) {
                        if (response.data.isRestrictAccess == true) {
                          this.watchlistNotMatch.push(
                            item.isin +
                              ', due to either restricted access or permission being needed for that Region.'
                          );
                          resolve({
                            status: false,
                            isDefault: false,
                            isin: item.isin,
                            message:
                              "You don't currently have a permission to upload the data with this Region. The data will not be uploaded."
                          });
                        } else {
                          resolve({
                            status: false,
                            isDefault: false,
                            isin: item.isin,
                            message:
                              'Data match warning - Some data did not match with database.'
                          });
                        }
                      } else {
                        if (regionIdTemp != response.data.regionID) {
                          resolve({
                            status: true,
                            isDefault: true,
                            isin: item.isin,
                            message: 'Region changes to default'
                          });
                        }

                        item.tickerID = response.data.tickerID;
                        item.company_name = response.data.name;
                        item.domicile_country = response.data.domicile_country;
                        item.region_name = response.data.region_name;
                        item.region_id = response.data.regionID;
                        // let isis = !isNaN(item.risk_score);
                        item.final_score =
                          item.risk_score && !isNaN(item.risk_score)
                            ? item.risk_score
                            : response.data.final_score;
                        item.watchlist_name = item.watchlist_name
                          ? item.watchlist_name
                          : fileName;
                        item.status = true;
                        data.splice(
                          data.findIndex(
                            (obj) =>
                              item.isin == obj.isin &&
                              item.region_id == obj.region_id &&
                              item.watchlist_name == obj.watchlist_name
                          ),
                          1,
                          item
                        );
                        resolve({
                          status: true,
                          isDefault: false,
                          isin: item.isin,
                          message: 'Upload complete.'
                        });
                      }
                    });
                } else {
                  this.watchlistNotMatch.push(
                    'Error empty stock data uploaded'
                  );
                  resolve({
                    status: false,
                    message: 'Upload failed. Make sure your data is correct.'
                  });
                }
              })
            );
          }
          Promise.all(promises).then((response) => {
            const watchlistTemp = [];
            for (let item of data) {
              // Handling empty row
              let responseTmp = response.find((x) => x.isin == item.isin);
              if (
                responseTmp.status == true &&
                item.isin &&
                item.company_name &&
                item.domicile_country &&
                item.region_id &&
                item.region_name &&
                item.watchlist_name
              ) {
                let index = watchlistTemp.findIndex(
                  (x) => x.name == item.watchlist_name
                );
                let company = null;
                if (item.status) {
                  company = {
                    tickerID: parseInt(item.tickerID),
                    isin: item.isin,
                    name: item.company_name,
                    domicile_country: item.domicile_country,
                    regionID: parseInt(item.region_id),
                    region_name: item.region_name,
                    final_score: item.final_score
                  };
                }
                if (index >= 0) {
                  // Only valid items are added
                  if (item.status) watchlistTemp[index].companies.push(company);
                } else {
                  watchlistTemp.push({
                    name: item.watchlist_name,
                    companies: company ? [company] : []
                  });
                }
              }
            }

            let trueResponse = response.find((x) => x.status == true);
            let falseResponse = response.find((x) => x.status == false);
            let defaultResponse = response.find((x) => x.isDefault == true);

            for (let watchlist of watchlistTemp) {
              let number = 0;
              let result = this.$store.state.watchlists.filter(
                (x) =>
                  x.name == watchlist.name ||
                  x.name.substr(0, x.name.lastIndexOf('(')) == watchlist.name
              );
              if (result && result.length > 0) {
                number = Math.max(
                  ...result.map((x) => {
                    if (x.name == watchlist.name) {
                      return 0;
                    }
                    let index = x.name.lastIndexOf('(');
                    let n = x.name
                      .substr(index)
                      .replace('(', '')
                      .replace(')', '');
                    return parseInt(isNaN(n) || n == x.name ? 0 : n);
                  })
                );
                watchlist.name =
                  watchlist.name + '(' + parseInt(number + 1) + ')';
              }

              this.$store.commit('saveWatchlist', watchlist);
            }

            if (
              this.watchlistNotMatch.length > 0 &&
              ((trueResponse != null && falseResponse != null) ||
                defaultResponse != null)
            ) {
              // Updated based on Peter's suggestion and approved by the client
              this.watchlistMessage =
                'Some stocks did not upload, as the request included stocks which are either not compatible with the latest dataset ' +
                "or those stocks require permission to upload data from that Region (you don't currently have access to that Region).";
              this.watchlistFooterMessage =
                'Please see your list of Saved Watchlist for more details about the successfully uploaded data';
              this.watchlistNotMatchDialog = true;
            }
            // else if (this.watchlistNotMatch.length > 0 && ((trueResponse != null && falseResponse != null) || defaultResponse!= null)) {
            // 	this.watchlistMessage = "These stocks did not upload, as the request included stocks which are not compatible with the latest dataset.";
            // 	this.watchlistFooterMessage = null;
            // 	this.watchlistNotMatchDialog = true;
            // }
            else if (
              (this.watchlistNotMatch.length > 0 && falseResponse != null) ||
              falseResponse != null
            ) {
              vm.popupNotificationWidget.show(falseResponse.message, 'error');
            } else if (data.length == 0) {
              vm.popupNotificationWidget.show('Empty Data', 'error');
            } else {
              vm.popupNotificationWidget.show(response[0].message, 'success');
            }
          });
        };
        reader.readAsText(file);
      } else {
        this.popupNotificationWidget.show('Unsupported file type', 'warning');
      }
      this.$refs.watchlistFile.value = null;
    },
    csvToArray(str, delimiter = ',') {
      // handling newline issue
      str = str.replace(/(\r\n|\n|\r)/gm, '\n');

      // slice from start of text to the first \n index
      // use split to create an array from string by delimiter
      const headers = str.slice(0, str.indexOf('\n')).split(delimiter);

      let idx = str.indexOf('\n') + 1;
      if (idx == 0) {
        return [];
      }

      // slice from \n index + 1 to the end of the text
      // use split to create an array of each csv value row
      const tmpRows = str.slice(idx).split('\n');
      //removing unformatted or empty line
      const rows = tmpRows.filter((row) => row);
      // Map the rows
      // split values from each row into an array
      // use headers.reduce to create an object
      // object properties derived from headers:values
      // the object passed as an element of the array
      const arr = rows.map(function (row) {
        const values = row.split(delimiter);
        const el = headers.reduce(function (object, header, index) {
          object[header] = values[index];
          return object;
        }, {});
        return el;
      });

      // return the array
      return arr;
    },
    hoverWatchlist(item) {
      let result = '';
      for (let watchlist of this.$store.state.watchlists) {
        if (
          watchlist.companies.find(
            (x) => x.tickerID == item.tickerID && x.regionID == item.regionID
          )
        ) {
          result = result + watchlist.name + '<br />';
        }
      }
      return result;
    },
    // ---------------
    async initFilter() {
      if (this.$store.state.isShareUrl) {
        this.isShare = true;
        this.initFlag = false;
        this.loadSavedFilter(this.$store.state.shareFilter);
      } else {
        await this.fetchRegion();
        await this.fetchYear();
        this.fetchOthersFilterOptions();
        this.applyFilter();
      }
    },
    async fetchRegion() {
      const responseREG = await axios.post('/dashboard/region');
      this.region = responseREG.data;
      this.filter.order = [];
      this.filter.order.push('REG');
      if (this.region.length > 0) {
        this.filter.regionId = this.region[0].regionID;
        this.defaultRegionId = this.$root.clone(this.region[0].regionID);
      }
    },
    loadFilterOptions(dropDownName, isEmpty) {
      this.$root.loader.dynamicFilter = false;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.deBounceLoadFilterOptions(dropDownName, isEmpty);
      }, 500);
    },
    async deBounceLoadFilterOptions(dropDownName, isEmpty) {
      let available = { is: false, index: null, is_empty: false };
      this.filter.order.forEach((order, index) => {
        if (order == dropDownName) {
          available.is = true;
          available.index = index;
          available.is_empty = isEmpty;
        }
      });
      if (available.is_empty) {
        this.filter.order.splice(available.index, 1);
        await this.checkAvailableOrder(available.is, dropDownName);
      } else if (available.is) {
        // this.filter.order.forEach((order, index) => {
        // 	if (index == available.index) {
        // 		this.filter.order.length = index+1;
        // 	}
        // });
        // this.filter.order.length = available.index+1;
        await this.checkAvailableOrder(available.is, dropDownName);
      } else {
        await this.checkAvailableOrder(available.is, dropDownName);
      }
    },
    async checkAvailableOrder(available, dropDownName) {
      if (!available) {
        this.filter.order.push(dropDownName);
      }
      await this.resetDynamicFlag();
      this.filter.order.forEach((order) => {
        if (order == 'RS') {
          this.dynamicFlag.rs = true;
        } else if (order == 'SEC') {
          this.dynamicFlag.sec = true;
        } else if (order == 'IG') {
          this.dynamicFlag.ig = true;
        } else if (order == 'AUD') {
          this.dynamicFlag.aud = true;
        } else if (order == 'DOM') {
          this.dynamicFlag.dom = true;
        } else if (order == 'EXC') {
          this.dynamicFlag.exc = true;
        } else if (order == 'INC') {
          this.dynamicFlag.inc = true;
        } else if (order == 'STA') {
          this.dynamicFlag.sta = true;
        } else if (order == 'OYC') {
          this.dynamicFlag.oyc = true;
        } else if (order == 'MCD') {
          this.dynamicFlag.mcd = true;
        } else if (order == 'RAT') {
          this.dynamicFlag.rat = true;
        }
      });
      await this.loadUnsellectFilterOptions();
    },
    loadUnsellectFilterOptions() {
      this.filter.year = null;
      this.clearDataFilter();
      this.$nextTick(() => {
        if (this.dynamicFlag.rs == false) {
          this.fetchRiskScore();
        }
        if (this.dynamicFlag.sec == false) {
          this.fetchSector();
        }
        if (this.dynamicFlag.ig == false) {
          this.fetchIndustry();
        }
        if (this.dynamicFlag.aud == false) {
          this.fetchAuditor();
        }
        if (this.dynamicFlag.dom == false) {
          this.fetchDomicile();
        }
        if (this.dynamicFlag.exc == false) {
          this.fetchExchange();
        }
        if (this.dynamicFlag.inc == false) {
          this.fetchIncorporation();
        }
        if (this.dynamicFlag.sta == false) {
          this.fetchStatus();
        }
        if (this.dynamicFlag.oyc == false) {
          this.fetchOneYearChange();
        }
        if (this.dynamicFlag.mcd == false) {
          this.fetchMarketCapDecile();
        }
        if (this.dynamicFlag.yr == false) {
          this.fetchYear();
        }
        if (this.dynamicFlag.rat == false) {
          this.fetchRating();
        }
      });
    },
    fetchOthersFilterOptions() {
      // this.fetchYear();
      this.fetchRiskScore();
      this.fetchSector();
      this.fetchIndustry();
      this.fetchAuditor();
      this.fetchDomicile();
      this.fetchExchange();
      this.fetchIncorporation();
      this.fetchStatus();
      this.fetchOneYearChange();
      this.fetchMarketCapDecile();
      this.fetchRating();
    },
    saveSelectedYear() {
      this.lastSelectedYear = this.tempYear;
    },
    async fetchYear() {
      this.loaderImage.year = true;
      const responseYear = await axios.post('/dashboard/year', this.filter);
      this.loaderImage.year = false;
      this.year = responseYear.data;
      // check for available last selected year on new year value
      if (
        this.lastSelectedYear != null &&
        this.year.includes(this.lastSelectedYear)
      ) {
        this.tempYear = this.lastSelectedYear;
      }
    },
    async fetchRiskScore() {
      axios.post('/dashboard/riskScore', this.filter).then((response) => {
        this.riskScoreDefault = [
          parseFloat(Number(response.data[0].min).toFixed(2)),
          parseFloat(Number(response.data[0].max).toFixed(2))
        ];
        this.riskScore = this.$root.clone(this.riskScoreDefault);
      });
    },
    async fetchSector() {
      this.loaderImage.sec = true;
      axios.post('/dashboard/sector', this.filter).then((response) => {
        this.loaderImage.sec = false;
        this.sector = response.data;
      });
    },
    async fetchIndustry() {
      this.loaderImage.ig = true;
      axios.post('/dashboard/industry', this.filter).then((response) => {
        this.loaderImage.ig = false;
        this.industry = response.data;
      });
    },
    async fetchAuditor() {
      this.loaderImage.aud = true;
      axios.post('/dashboard/auditor', this.filter).then((response) => {
        this.loaderImage.aud = false;
        this.auditor = response.data;
      });
    },
    async fetchDomicile() {
      this.loaderImage.dom = true;
      const response = await axios.post('/dashboard/domicile', this.filter);
      // axios.post("/dashboard/domicile", this.filter).then((response) => {
      this.loaderImage.dom = false;
      this.domicile = response.data;
      // });
    },
    async fetchExchange() {
      this.loaderImage.exc = true;
      axios.post('/dashboard/exchange', this.filter).then((response) => {
        this.loaderImage.exc = false;
        this.exchange = response.data;
      });
    },
    async fetchIncorporation() {
      this.loaderImage.inc = true;
      axios.post('/dashboard/incorporation', this.filter).then((response) => {
        this.loaderImage.inc = false;
        this.incorporation = response.data;
      });
    },
    async fetchStatus() {
      this.loaderImage.sta = true;
      axios.post('/dashboard/status', this.filter).then((response) => {
        this.loaderImage.sta = false;
        this.status = response.data;
      });
    },
    async fetchOneYearChange() {
      const responseOYC = await axios.post(
        '/dashboard/oneYearChange',
        this.filter
      );
      this.oneYearChangeDefault = [
        Math.round(Number(responseOYC.data[0].min)),
        Math.round(Number(responseOYC.data[0].max))
      ];
      this.oneYearChange = this.$root.clone(this.oneYearChangeDefault);
    },
    async fetchMarketCapDecile() {
      const responseMCD = await axios.post('/dashboard/marketCD', this.filter);
      this.marketCapDecileDefault = [
        Number(responseMCD.data[0].min),
        Number(responseMCD.data[0].max)
      ];
      this.marketCapDecile = this.$root.clone(this.marketCapDecileDefault);
    },
    async fetchRating() {
      this.loaderImage.rat = true;

      const response = await axios.post('/dashboard/rating', this.filter);
      this.loaderImage.rat = false;
      this.rating = response.data.sort((x, y) => {
        if (x.rating < y.rating) {
          return -1;
        }
        if (x.rating > y.rating) {
          return 1;
        }
        return 0;
      });

      // if (this.rating.find(({ rating }) => rating === this.previousRating)) {
      //   this.filter.rating = this.previousRating;
      // }

      return response;
    },
    // ---------------
    clearFilter() {
      this.filter = {
        order: ['REG'],
        sectorId: [],
        industryGroupId: [],
        auditorId: [],
        domicile: [],
        exchange: [],
        incorporation: [],
        status: [],
        rating: [],
        index: 0,
        maxResult: 100,
        minOneYearChange: null,
        maxOneYearChange: null,
        minMarketCapDecile: null,
        maxMarketCapDecile: null,
        minRiskScore: null,
        maxRiskScore: null,
        pageToken: null,
        regionId: null,
        year: this.$root.clone(this.filter.year)
      };
      this.count = {
        regionCount: null,
        riskScoreCount: null,
        sectorCount: null,
        industryCount: null,
        auditorCount: null,
        domicileCount: null,
        incorporationCount: null,
        exchangeCount: null,
        statusCount: null,
        oneYearChangeCount: null,
        marketCapDecileCount: null
      };
      this.lastSelectedYear = null;
      this.regionNotAvailable = false;
      this.filter.regionId = this.region[0].regionID;
      this.tempYear = this.year[0];
    },
    resetDynamicFlag() {
      this.dynamicFlag = {
        // reg: false,
        rs: false,
        sec: false,
        ig: false,
        aud: false,
        dom: false,
        exc: false,
        inc: false,
        sta: false,
        oyc: false,
        mcd: false,
        yr: false,
        rat: false
      };
    },
    clearDataFilter() {
      if (this.dynamicFlag.rs == false) {
        // this.riskScore = [];
        this.filter.maxRiskScore = null;
        this.filter.minRiskScore = null;
      }
      if (this.dynamicFlag.sec == false) {
        this.filter.sectorId = [];
      }
      if (this.dynamicFlag.ig == false) {
        this.filter.industryGroupId = [];
      }
      if (this.dynamicFlag.aud == false) {
        this.filter.auditorId = [];
      }
      if (this.dynamicFlag.dom == false) {
        // this.domicile = [];
        this.filter.domicile = [];
      }
      if (this.dynamicFlag.exc == false) {
        // this.exchange = [];
        this.filter.exchange = [];
      }
      if (this.dynamicFlag.inc == false) {
        this.filter.incorporation = [];
      }
      if (this.dynamicFlag.sta == false) {
        this.filter.status = [];
      }
      if (this.dynamicFlag.oyc == false) {
        // this.oneYearChange = [];
        this.filter.maxOneYearChange = null;
        this.filter.minOneYearChange = null;
      }
      if (this.dynamicFlag.mcd == false) {
        // this.marketCapDecile = [];
        this.filter.maxMarketCapDecile = null;
        this.filter.minMarketCapDecile = null;
      }
      if (this.dynamicFlag.rat == false) {
        this.filter.rating = [];
      }
    },
    async clearAllFilter() {
      this.$root.loader.dynamicFilter = false;
      await this.clearFilter();
      this.filter.regionId = this.defaultRegionId;
    },
    formatedNumber(value) {
      if (value < 10 && value >= 0) {
        value = '0' + value;
      }
      return value;
    },
    activeSliderBorder(id) {
      let doc = document.getElementById(id);
      if (doc != null) doc.classList.add('active-slider');
    },
    inactiveSliderBorder(id) {
      let doc = document.getElementById(id);
      if (doc != null) doc.classList.remove('active-slider');
    },
    downloadConfirm() {
      this.downloadDialog = false;
      this.downloadSaveDialog = true;
    },
    downloadResult() {
      if (this.fileNameFilter) {
        const dataURI =
          'data:text/plain;base64,' +
          encodeBase64(this.convertToCSV(this.dataItems));
        saveAs(dataURI, this.fileNameFilter + '.csv');
        this.downloadSaveDialog = false;
        this.fileNameFilter = null;
      }
    },
    convertToCSV(jsonData) {
      var csvStr = 'ISIN,Name,Domicile,Risk\r\n';
      jsonData = jsonData.slice(0, this.filterSizeRecord);
      for (var i = 0; i < jsonData.length; i++) {
        csvStr +=
          jsonData[i].isin +
          ',' +
          this.commaEscape(jsonData[i].name) +
          ',' +
          jsonData[i].domicile_country +
          ',' +
          Math.round(jsonData[i].final_score * 100) +
          '%' +
          '\r\n';
      }
      return csvStr;
    },
    commaEscape(str) {
      if (str.includes(',')) {
        str = '"' + str + '"';
      }
      return str;
    },
    downloadFilterResult() {
      axios
        .post('/setting/filter')
        .then((response) => {
          this.filterSizeRecord = parseInt(response.data.value);
        })
        .then(
          this.$nextTick(() => {
            this.downloadDialog = true;
          })
        );
    },
    rerenderRangeSlider() {
      let riskScoreTemp = this.$root.clone(this.riskScore);
      let oneYearChangeTemp = this.$root.clone(this.oneYearChange);
      let marketCapDecileTemp = this.$root.clone(this.marketCapDecile);
      this.riskScore = this.$root.clone(this.riskScoreDefault);
      this.oneYearChange = this.$root.clone(this.oneYearChangeDefault);
      this.marketCapDecile = this.$root.clone(this.marketCapDecileDefault);
      this.rerender = false;
      this.$nextTick(() => {
        this.rerender = true;
        this.$nextTick(() => {
          this.riskScore = riskScoreTemp;
          this.oneYearChange = oneYearChangeTemp;
          this.marketCapDecile = marketCapDecileTemp;
        });
      });
    },
    emptyString(value) {
      return value ? value : '-';
    }
  }
};
</script>

<style lang="scss" scoped>
.warning {
  background: #b1323a;
  color: white;
  padding: 5px 20px;
  border-radius: 0.188rem;

  .warning-icon {
    margin-right: 8px;
  }
}
</style>
