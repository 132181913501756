import { mapMutations } from 'vuex';
import getUserSubscriptionMixin from '@/components/tenantAdmin/mixins/getUserSubscription';

export default {
  mixins: [getUserSubscriptionMixin],
  methods: {
    ...mapMutations('fastspring', ['SET_IS_FIRST_TENANT_ADMIN_LOGIN']),
    async redirectAfterLogin(result) {
      const res = result.response.data;

      if (!res.idToken) {
        const notification = {
          title: 'Notification',
          content: res.message
        };

        this.$store.commit('showNotification', notification);
        return;
      }

      const { principal, fastspring } = this.$store.state;

      if (principal.firstLogin) {
        this.$router.push('/createPassword');
        return;
      }

      if (principal.administrator) {
        this.$store.commit('agreement');
        this.$router.push('/administrator/tenant');
        return;
      }

      if (principal.tenantAdministrator) {
        let url = '/manageUsers';

        if (fastspring.isFirstTenantAdminLogin) {
          url = '/payment';
          fastspring.isFirstTenantAdminLogin = false;
        }

        await this.getSubscriptionInfo();

        // User already subscribed => Redirect to dashboard instead of user management
        if (
          fastspring.subscription &&
          fastspring.subscription.fastspringSubscriptionId
        ) {
          url = '/dashboard';
        }

        this.$store.commit('agreement');
        this.$router.push(url);

        return;
      }

      this.$router.push('/termsAndConditions');
    }
  }
};
