<template>
  <k-multi-select
    v-model="selectedItems"
    :data-items="items"
    :text-field="textField"
    :data-item-key="dataItemKey"
    :placeholder="placeholder"
    :disabled="useLoader"
    header="myHeader"
    item-render="myItemRender"
    @filterchange="onFilterChange"
    :filterable="true"
    :loading="useLoader"
    @change="onChange"
    @open="onOpen"
    @close="onClose"
    :auto-close="false"
    tag-render="myTags"
    :tags="tags"
    :opened="showDropDown"
    v-click-outside="onClickOutside"
  >
    <template v-slot:myTags="{ props }">
      <li class="k-chip">
        {{ props.tagData.text }}
      </li>
    </template>
    <template v-slot:myHeader>
      <div
        class="tab-filter my-1 d-flex align-items-center ms-2 w-100 custom primary"
        @click.stop="onSelectAll"
      >
        <input
          class="k-checkbox k-checkbox-md k-rounded-md me-2"
          id="selectAll"
          type="checkbox"
          @click.stop="onSelectAll"
          :checked="selectAll"
        />
        <label>(All)</label>
      </div>
    </template>
    <template v-slot:myItemRender="{ props }">
      <item-render
        :class="props.itemClass"
        :data-item="props.dataItem[textField]"
        :selected="props.selected"
        :index="props.index"
        @click="(ev) => props.onClick(ev)"
      />
    </template>
  </k-multi-select>
</template>
<script>
import { filterBy } from '@progress/kendo-data-query';
import ItemRender from './ItemRender';
import $ from 'jquery';
import vClickOutside from 'v-click-outside';
const delay = 500;
export default {
  props: {
    source: Array,
    value: Array,
    textField: String,
    dataItemKey: String,
    placeholder: String,
    customSelected: Array,
    dropDownName: String,
    // autoSelectAll default is 'false' when parent didn't send any value props
    autoSelectAll: Boolean,
    // for loader image on the left form (default is false)
    useLoader: Boolean
  },
  components: {
    'item-render': ItemRender
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      items: this.source != null ? this.source.slice() : [],
      selectedItems: [],
      selectAll: true, // Default select all when first load
      // loader: this.useLoader,
      showDropDown: false,
      isClose: false,
      selectedRegions: []
    };
  },
  computed: {
    selected() {
      return this.selectedItems.length;
    },
    tags() {
      return this.selected > 0
        ? [
            {
              text: `${
                this.selected == 1
                  ? this.selectedItems[0][this.textField]
                  : this.source.length == this.selectedItems.length
                  ? '(All)'
                  : '(Multiple values)'
              }`,
              data: [...this.selectedItems]
            }
          ]
        : [];
    }
  },
  watch: {
    tags() {
      // this.selectAll = this.items.length == this.selectedItems.length;
      this.handleInput();
    },
    source(newV, oldV) {
      if (newV.length != oldV.length) {
        // Initialize data for dinamically source items
        this.items = newV.slice();
        // Check for auto select all when first load
        this.checkDefaultSelectedForm();
      }
    },
    value(newV, oldV) {
      if (
        newV.length != oldV.length &&
        !Object.keys(newV).some((key) => key === this.dataItemKey)
      ) {
        this.selectedItems = newV.map((v) =>
          this.source.find((s) => s[this.dataItemKey] == v)
        );
      }
    },
    selectedItems(newV, oldV) {
      if (newV.length != oldV.length) {
        this.checkMultiSelect(newV);
      }
      if (newV.length == this.items.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    }
  },
  methods: {
    onClickOutside() {
      this.showDropDown = false;
    },
    onFilterChange(event) {
      let vm = this;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        vm.items = filterBy(vm.source.slice(), event.filter);
        vm.loading = false;
      }, delay);
      this.loading = true;
    },
    onSelectAll() {
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        this.selectedItems = [];
        for (let item of this.items) {
          this.selectedItems.push(item);
        }
      } else {
        this.selectedItems = [];
        this.isClose = false;
      }
      this.loadFilterOptions();
    },
    isCustom(item) {
      return item.id === undefined;
    },
    addKey(item) {
      item.id = new Date().getTime();
    },
    onChange(event) {
      const values = event.target.value;
      const lastItem = values[values.length - 1];
      if (lastItem && this.isCustom(lastItem)) {
        values.pop();
        const sameItem = values.find(
          (v) => v[this.dataItemKey] === lastItem[this.dataItemKey]
        );
        if (sameItem === undefined) {
          this.addKey(lastItem);
          values.push(lastItem);
        }
      }
      this.selectedItems = values;
      if (this.selectedItems.length > 0) {
        this.isClose = false;
      }
      this.loadFilterOptions();
    },
    onOpen() {
      this.showDropDown = true;
      this.isClose = true;
      this.$nextTick(() => {
        $('.k-list-content').scrollTop(0);
      });
    },
    onClose() {
      if (this.isClose == true) {
        this.showDropDown = false;
        this.isClose = false;
        return;
      }
      if (this.selectedItems.length === 0) {
        this.showDropDown = true;
        this.isClose = true;
      } else {
        this.showDropDown = false;
      }
    },
    handleInput() {
      if (this.textField == 'region_name') {
        this.selectedRegions = [...this.selectedItems];
        this.$emit('selectedRegion', this.selectedRegions);
      }

      this.$emit(
        'input',
        this.selectedItems.map((item) => item[this.dataItemKey])
      );
    },
    checkMultiSelect(value) {
      if (value.length == this.items.length) {
        this.showDropDown = false;
      }
    },
    checkDefaultSelectedForm() {
      let selectedItems = [];
      // #1- Check if there is custom selected data
      if (this.customSelected != null) {
        //----- Use this code below for MVP 1.5
        selectedItems = this.customSelected.slice() || [];
        //----- Use this code below for MVP 1.4
        // let customSelected = this.customSelected.map((item) => {
        //     return {...item, regionID: item.regionID.toString()}
        // });
        // selectedItems = customSelected.slice() || [];

        // #2- is autoSelectAll = True or False.?
      } else if (this.autoSelectAll) {
        selectedItems = this.source != null ? this.source.slice() : [];
      }
      this.selectedItems = selectedItems;
    },
    checkSelectedItems() {
      if (this.selectedItems.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    loadFilterOptions() {
      this.$emit(
        'loadFilterOptions',
        this.dropDownName,
        this.checkSelectedItems()
      );
    }
  },
  mounted() {
    this.checkDefaultSelectedForm();
  }
};
</script>
