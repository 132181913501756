import axios from 'axios';

export default {
  methods: {
    async getUserSubscriptionEstimate(userSubscriptionInformation) {
      const response = await axios.post('payment/estimateSubscription', {
        ...userSubscriptionInformation
      });

      if (response.status === 200 && response.data) {
        return response.data.data;
      }

      return null;
    }
  }
};
