<template>
  <div class="layout layout--public">
    <div class="layout__body">
      <div class="container">
        <div class="back-button" @click="$router.push('/verification')">
          <img src="@/assets/img/arrow-left.svg" alt="" />
          Back
        </div>
        <div class="row">
          <div class="col-12">
            <icon-logo />
          </div>
        </div>
        <k-form
          ref="form"
          :validator="mfaCodeValidator"
          @submit="mfaSignIn"
          autocomplete="off"
        >
          <verification-form v-on:resendCode="resendCode" />
        </k-form>
        <vue-recaptcha
          hidden="true"
          ref="recaptcha"
          @verify="onCaptchaVerified"
          @expired="resetCaptcha"
          @error="errorMethod"
          size="invisible"
          :sitekey="$recaptchaSiteKey"
        ></vue-recaptcha>
      </div>
    </div>
  </div>
</template>

<script>
import verificationForm from './verificationForm';
import { mfaCodeValidator } from '@/plugins/validator';
import loginRedirectMixin from '@/shared/mixins/loginRedirect';

export default {
  components: {
    verificationForm
  },
  mixins: [loginRedirectMixin],
  data: function () {
    return {
      mfaCodeValidator
    };
  },
  mounted() {
    this.$refs.form.$el.setAttribute('autocomplete', 'off');
  },
  methods: {
    onCaptchaVerified(token) {
      this.$store
        .dispatch('prepareOtp', {
          sendType: this.$store.state.verification.verifyType,
          token: token
        })
        .then((result) => {
          if (result.response.status === 200) {
            this.$store.state.notification.show = true;
            this.$store.state.notification.title = 'success';
            this.$store.state.notification.content = 'SUCCESS_RESEND_CODE';
          }
        });
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset();
    },
    resendCode() {
      this.$refs.recaptcha.execute();
    },
    errorMethod() {
      console.log('reCaptcha Error');
    },
    mfaSignIn(dataItem) {
      if (!this.$refs.form.isValid()) {
        return;
      }

      this.$store
        .dispatch('mfaSignInv2', {
          code: dataItem.code
        })
        .then(this.redirectAfterLogin);
    }
  }
};
</script>

<style scss scoped>
.back-button {
  cursor: pointer;
  color: white;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }

  img {
    padding: 6px;
    border: 1px solid #fff;
    border-radius: 8px;
    margin-right: 8px;
  }
}
</style>
