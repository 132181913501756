<template>
  <div class="header">
    <div class="container d-flex justify-content-between">
      <icon-logo-header class="header-img" />

      <div class="header-buttons">
        <k-button
          class="header-button me-3"
          @click="goToDashboard"
          v-if="
            isUserUsingTrialSubscription ? !isTrialSubscriptionExpired : true
          "
        >
          Dashboard
        </k-button>

        <template>
          <k-button
            class="header-button me-3"
            @click="showNavigationPopup = !showNavigationPopup"
            ref="button"
            v-if="
              !isCancelingSubscription ||
              (!isUserUsingTrialSubscription && !isPaymentOverdue)
            "
            v-click-outside="closeNavigationPopup"
          >
            <span>Subscription</span>
            <span
              class="k-icon k-panelbar-toggle k-panelbar-expand k-i-arrow-chevron-down"
            ></span>
          </k-button>

          <k-popup
            :anchor="'button'"
            :show="showNavigationPopup"
            :popup-class="'popup-content'"
          >
            <template v-if="!isCancelingSubscription">
              <k-button
                class="header-button header-button--small mx-2 my-1"
                @click="goTo('/subscriptionPlans')"
                v-if="
                  this.subscription.fastspringSubscriptionId &&
                  this.isUserUsingTrialSubscription
                "
              >
                <icon-change />
                <span>Upgrade Subscription Plan</span>
                <!-- <span v-else>Change Subscription Plan</span> -->
              </k-button>

              <k-button
                class="header-button header-button--small mx-2 my-1"
                @click="goTo('/payment')"
                v-if="!isUserUsingTrialSubscription"
              >
                <icon-update-subscription />
                <span>Update Subscription</span>
              </k-button>

              <k-button
                class="header-button header-button--small mx-2 my-1"
                @click="$emit('openSubscriptionDetailDialog')"
                v-if="!isUserUsingTrialSubscription"
              >
                <icon-account />
                <span>Subscription Details</span>
              </k-button>
            </template>

            <k-button
              class="header-button header-button--small mx-2 my-1"
              @click="goTo('/cancel-subscription')"
              v-if="!isUserUsingTrialSubscription && !isPaymentOverdue"
            >
              <template v-if="!isCancelingSubscription">
                <icon-close />
                <span>Cancel Subscription</span>
              </template>

              <template v-else>
                <icon-check />
                <span>Resume Subscription</span>
              </template>
            </k-button>
          </k-popup>
        </template>

        <k-button class="header-button me-3" @click="showLogoutDialog = true">
          <icon-logout />
          <span>Logout</span>
        </k-button>

        <div class="header-button subscription-info">
          <div class="icon-col">
            <icon-push-notification />
          </div>
          <div class="text-col">
            <div class="text">{{ subscriptionInfoText }}</div>
            <div class="buy-more-btn-row">
              <button class="buy-more-btn" @click="goTo('/payment')">
                Buy More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <k-dialog
      v-if="showLogoutDialog"
      :title="' '"
      @close="showLogoutDialog = false"
    >
      <div class="row">
        <div class="col-12">
          <p class="title d-flex justify-content-center">
            Log out of Transparently.AI
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-2 d-flex justify-content-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="showLogoutDialog = false"
          >
            Cancel
          </k-button>

          <k-button theme-color="primary" @click="logout"> Yes </k-button>
        </div>
      </div>
    </k-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import userSubscriptionMixin from './mixins/userSubscription';

export default {
  mixins: [userSubscriptionMixin],
  props: {
    remainingSeatCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showLogoutDialog: false,
      showNavigationPopup: false
    };
  },
  computed: {
    ...mapGetters('fastspring', ['subscription', 'isCancelingSubscription']),
    subscriptionInfoText() {
      const seatText = this.remainingSeatCount > 1 ? 'seats' : 'seat';
      return `${this.remainingSeatCount || 0} ${seatText} left!`;
    }
  },
  methods: {
    ...mapMutations(['showNotification']),
    goTo(url) {
      this.$router.push(url);
    },
    goToDashboard() {
      if (this.subscription.seatCount > 0) {
        this.$router.push('/dashboard');
      } else {
        const notification = {
          title: '',
          content: `You haven't subscribed to any bundle yet. Please update your subscription first.`
        };
        this.showNotification(notification);
      }
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/');
      });
    },
    closeNavigationPopup() {
      this.showNavigationPopup = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  background-color: #13151a;
  padding: 20px 0;
  margin-bottom: 56px;
}

.header-img {
  width: 204px;
  height: 72px;
}

.header-buttons {
  display: flex;
  align-items: center;
}

.header-button {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 14px 22px 14px 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  height: 48px;

  &--small {
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    padding: 10px 14px;

    svg {
      max-width: 20px;
    }
  }
}

.subscription-info {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e05659;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 26px 14px 14px;
  height: auto;

  .icon-col {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    background-color: rgba(224, 86, 89, 0.1);
    border-radius: 50%;
  }

  .icon-text {
    flex: 1;
  }

  .buy-more-btn-row {
    margin-top: 6px;
  }

  .buy-more-btn {
    outline: none;
    background: none;
    border: none;
    color: #ea8f36;
    font-weight: 600;
  }
}
</style>
