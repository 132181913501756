<template>
  <div>
    <div v-if="showViewer == false" class="loader">
      <div class="k-overlay"></div>
      <img src="@/assets/img/loader.svg" />
    </div>

    <div
      class="dashboard-report hidden-report-Container"
      v-if="showViewer == false"
    >
      <div id="pdfContainer">
        <div class="mx-2">
          <div
            class="row report-header justify-content-end mb-3"
            style="width: 100%"
          >
            <div class="col-8">
              <icon-logo-report-header />
            </div>
            <div class="col-4">
              <div class="row" style="text-align: right">
                <span style="font-weight: bold">PRIVATE & CONFIDENTIAL</span>
                <span>Report generated on {{ dateToday }}</span>
              </div>
            </div>
          </div>
          <div class="report-header-title" style="width: 100%">
            <div class="row">
              <div class="col-8">
                <div class="row mb-3">
                  <span
                    class="dashboard--small"
                    style="
                      font-weight: normal;
                      color: #4b5c6e;
                      font-weight: bold;
                    "
                    >{{ choosenYear }} : {{ ticker.industryGroup }}
                  </span>
                  <span
                    class="dashboard--large text-dark"
                    style="font-weight: bold"
                    >{{ ticker.isin }} {{ ticker.name }}
                    {{ ticker.status }}</span
                  >
                </div>
                <k-ticker-info
                  :ticker="ticker"
                  :isReportFlag="true"
                  v-on:rendered="validateRenderComponent"
                />
              </div>
              <div class="col-4">
                <k-radial-chart
                  ref="radialChart"
                  :chartValue="radialDetail"
                  :score="ticker.finalScore"
                  :isReportFlag="true"
                  v-on:rendered="validateRenderComponent"
                ></k-radial-chart>
              </div>
            </div>
          </div>
          <div class="row mb-5" style="width: 100%">
            <span class="dashboard--large text-dark" style="font-weight: bold"
              >Transparently.AI gives {{ ticker.name }} {{ score }}%
              score.</span
            >
          </div>
          <div class="row mb-3">
            <div class="col-4 d-flex justify-content-center">
              <k-history-chart
                id="history"
                ref="historyChart"
                class="report-diagram"
                :data.sync="historyChart"
                :name.sync="ticker.name"
                :isReportFlag="true"
                v-on:rendered="validateRenderComponent"
              />
            </div>
            <div class="col-4 d-flex justify-content-center">
              <k-distribution-chart
                class="report-diagram"
                :chart.sync="distSectorRisk"
                :isSectorRisk="true"
                :popups="false"
                :isReportFlag="true"
                v-on:rendered="validateRenderComponent"
              />
            </div>
            <div class="col-4 d-flex justify-content-center">
              <k-distribution-chart
                class="report-diagram"
                :chart.sync="distRisk"
                :isSectorRisk="false"
                :popups="false"
                :isReportFlag="true"
                v-on:rendered="validateRenderComponent"
              />
            </div>
          </div>
          <div class="row report-panel-card mb-5">
            <k-chart-detail
              ref="chartDetail"
              class="report-card"
              :radialDetail="chartDetail"
              :tickerId="ticker.id"
              :regionId="ticker.regionId"
              :year="choosenYear"
              :isReportFlag="true"
              v-on:rendered="validateRenderComponent"
            />
          </div>
          <!-- New Disclaimer -->
          <div class="row" style="width: 100%">
            <span class="disclaimer-font--header"
              ><strong>Disclaimer</strong></span
            >
            <div class="disclaimer-font mt-2">
              1. Unless otherwise specified, this Report adopts the terms and
              definitions under Transparently.AI’s Terms of Use and the Customer
              Services Agreement (“CSA”).
            </div>
            <span class="disclaimer-font mt-2">
              2. You are receiving this Report following the Customer’s
              subscription to the Services as governed by the CSA. This Report
              provides you with information relating to the analysis of the
              specific risk drivers and areas of accounting management
              presenting symptoms of risk, in respect of companies and financial
              years in the stock universe as set out in the CSA and as selected
              by you.
            </span>
            <span class="disclaimer-font mt-2">
              3. This Report and the information and/or opinions contained
              herein are private and confidential and intended for you only. All
              worldwide intellectual property rights subsisting in any
              information, graphics, and visual representations in the Report
              are our exclusive property. The Report should not be copied or
              otherwise distributed to any person, or published, in any manner
              and medium (electronic or otherwise), whether in whole or in part.
            </span>
            <span class="disclaimer-font mt-2">
              4. This Report is intended for general guidance and information
              purposes only. This Report is under no circumstances intended to
              be used or considered as financial or investment advice, a
              recommendation or an offer to sell or invest, or a solicitation of
              any offer to buy any securities or other form of financial asset.
              This is not an offer document. The Report is not to be considered
              as investment research or an objective or independent explanation
              of the matters contained herein. The contents of this report are
              not to be construed as legal, business, investment or tax advice.
              You should consult with you legal, business, investment and tax
              advisors as to legal, business, investment and tax advice. Nothing
              in this Report should be taken to impute fraud, dishonesty,
              intentional misrepresentation, willful misconduct or any kind
              impropriety to any of the companies that may be mentioned herein.
            </span>
            <span class="disclaimer-font mt-2">
              5. The information contained in this Report is provided “as is”,
              and we make no (and hereby disclaim all) other warranties,
              representations, or conditions, whether written, oral, express,
              implied or statutory, including, without limitation, any implied
              warranties of satisfactory quality, course of dealing, trade usage
              or practice, system integration, data accuracy, merchantability,
              title, noninfringement, or fitness for a particular purpose.
            </span>
            <span class="disclaimer-font mt-2">
              6. We shall not in any circumstances whatever be liable to you,
              whether in contract, tort (including negligence), breach of
              statutory duty, or otherwise, arising under or in connection with
              the Report and our provision of information herein for: (a) loss
              of profits, sales, business, or revenue; (b) business
              interruption; (c) loss of anticipated savings; (d) loss of
              business opportunity, goodwill or reputation; (whether any of the
              losses set out in (a)-(d) are direct or indirect) or (e) any
              special, indirect or consequential loss, damage, charges or
              expenses.
            </span>
            <span class="disclaimer-font mt-2">
              7. There may have been changes in matters which affect the
              information provided in the Report subsequent to the date of this
              Report. Neither the issue nor delivery of this Report shall under
              any circumstance create any implication that the information
              contained herein is correct as of any time subsequent to the date
              hereof or that the affairs of the company have not since changed.
              We do not intend, and do not assume any obligation to update or
              correct the information included in this Report.
            </span>
            <span class="disclaimer-font mt-2">
              8. Unless expressly permitted under the CSA, you will not use
              Transparently Information to create, issue, sponsor or calculated
              an Index that will be used (a) as, or forms the basis of, a
              financial product, including but not restricted to funds, futures,
              options, swaps, certificates, notes; or (b) as the official
              benchmark to measure and report the performance of a financial
              product. Save to the extent that you are permitted hereunder and
              under the CSA to modify or create Derived Data from Transparently
              Information or to Redistribute Transparently Information,
              including Insubstantial Portions of Information in a
              Non-Systematic manner, you shall not alter or distort the
              editorial meaning of any analysis included in Transparently
              Information.
            </span>
            <span class="disclaimer-font mt-2">
              9. Unless expressly permitted under the CSA, you will not use
              Transparently Information to construct or calculate an Index,
              Investment Strategy or a benchmark that will be Redistributed,
              licensed or otherwise published or shared outside of the
              Customer’s organisation.
            </span>
            <span class="disclaimer-font mt-2">
              10. You shall not download more than 3,000 unique CUSIP
              Identifiers, or 3,000 unique CINS Identifiers, or 3,000 unique CGS
              ISIN Identifiers during any consecutive three-month period.
            </span>
            <span class="disclaimer-font mt-2">
              11. The Report, and any dispute or claim (including
              non-contractual disputes or claims) arising out of or in
              connection with it shall be governed by and construed in
              accordance with the Governing Law.
            </span>
            <span class="disclaimer-font mt-2"> Copyright © 2022</span>
            <span class="disclaimer-font"> Transparently Pte. Ltd. </span>
          </div>
          <!-- Old Disclaimer -->
          <!-- <div class="row" style="width: 100%">
						<span class="disclaimer-font--header"><strong>Disclaimer</strong></span>
						<span class="disclaimer-font mt-2">
							This document has been prepared by Transparently Pte Limited (“Transparently”). This report does not constitute an offer to sell or the solicitation of an offer to buy any securities. You may not rely on this report as the
							basis upon which to make an investment decision. This report does not purport to be complete on any topic addressed. The information in this report is preliminary and subject to change without notice and is provided to you
							as of the dates indicated and Transparently does not intend to update the information after its distribution, even in the event that the information becomes materially inaccurate.
						</span>
						<span class="disclaimer-font mt-2">
							No representation, warranty or undertaking, express or implied, is made as to, and no reliance should be placed on, the fairness, accuracy, completeness or correctness of the information or the opinions contained herein.
							This report is not intended to provide, and should not be relied upon for, tax, legal, accounting or investment advice. Prospective investors should consult their own professional advisors concerning the acquisition,
							holding or disposal of the shares. Neither Transparently nor any of its respective affiliates, advisors, agents or representatives including directors, officers and employees shall have any liability whatsoever (in
							negligence or otherwise) for any loss howsoever arising from any use of this report or its contents or otherwise arising in connection with the report.
						</span>
						<span class="disclaimer-font mt-2">
							Manipulation probabilities, scores, and all accompanying data, results and analysis are based on mathematical models applied to publicly available data. Transparently has no access to company data that has not been
							publicly released. Transparently does not speak with company management, employees, suppliers, customers, lenders nor any other related party. Mathematical relationships are derived from identification of historical
							patterns and interactions that (with specified and estimated probability) are associated with manipulation and/or subsequent company failure (where failure is defined as removal from active trading status after a specified
							threshold level of poor return performance and/or meeting a poor return threshold level). None of Transparently’s analysis nor results definitively identify manipulation, fraud nor any other form of malfeasance.
							Manipulation probability signals may be a consequence of specific company features that are unrelated to manipulation and/or cyclical changes and/or structural changes and/or model specification errors that render the
							manipulation probability signals invalid. Results are subject to the risk of false positives and false negatives.
						</span>
						<span class="disclaimer-font mt-2">
							This report is strictly confidential and intended solely for the use of the intended recipient(s). The recipient of this report should not copy, share, reproduce, disclose or redistribute this report without permission
							from Transparently. This report is for information purposes only and should not be regarded as an offer to sell or as a solicitation of an offer to buy any financial product, or as an official statement of Transparently.
							The material is based upon information that Transparently considers reliable, but Transparently does not represent that it is accurate or complete, and it should not be relied upon as such. As such, Transparently does not
							accept any responsibility or liability for the accuracy or otherwise of such information.
						</span>
						<span class="disclaimer-font mt-2"> Copyright © 2022</span>
						<span class="disclaimer-font"> Transparently Pte. Ltd. </span>
					</div> -->
        </div>
      </div>
    </div>
    <div class="dashboard-report" v-show="showViewer == true">
      <iframe
        width="100%"
        height="100%"
        frameborder="0"
        scrolling="no"
        style="border: none"
        :src="pdfSrc"
      ></iframe>
    </div>
  </div>
</template>
<script>
import radialChart from './radialChart.vue';
import tickerInfo from '../dashboard/tickerInfo.vue';
import historyChart from '../dashboard/historyChart.vue';
import chartDetail from './chartDetail.vue';
import distributionChart from './chartDashboard.vue';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
require('../../assets/css/LocalFontOverride.css');

const TOTAL_COMPONENTS = 6;

export default {
  components: {
    'k-radial-chart': radialChart,
    'k-ticker-info': tickerInfo,
    'k-history-chart': historyChart,
    'k-chart-detail': chartDetail,
    'k-distribution-chart': distributionChart
  },
  data() {
    return {
      pdfSrc: null,
      showViewer: false,
      pdfFileName: 'report',
      reportValue: {
        chartDetail: [],
        choosenYear: null,
        ticker: {},
        riskChart: {},
        sectorRiskChart: {},
        historyChart: {},
        radialDetail: [],
        sectorRisk: 0,
        riskPoint: 0,
        categorySector: [],
        category: []
      },
      dateToday: null,
      choosenYear: 0,
      valueAxis: [
        {
          line: {
            visible: false
          }
        }
      ],
      risk: [],
      ticker: {
        isin: '',
        id: '',
        name: '',
        status: '',
        mv: 0,
        auditor: '',
        dlOptimistic: 0,
        dlPessimistic: 0,
        domicileCountry: '',
        equityLoss: '',
        exchangeCountry: '',
        finalScore: '',
        incorpCountry: '',
        industryGroup: '',
        rankPercent: 0,
        rfpAbsolute: 0,
        rfpRelative: 0,
        sector_name: '',
        telOptimistic: 0,
        telPessimistic: 0
      },
      historyChart: {
        score: [],
        average: [],
        year: []
      },
      chartDetail: [],
      radialDetail: [],
      score: null,
      loadingTime: 0,
      renderedComponent: 0,
      distSectorRisk: {
        year: 0,
        ticker: {},
        chart: {},
        point: 0,
        category: []
      },
      distRisk: {
        year: 0,
        ticker: {},
        chart: {},
        point: 0,
        category: []
      }
    };
  },
  created() {
    this.reportValue = JSON.parse(this.$route.query.reportValue);
    this.chartDetail = this.reportValue.chartDetail;
    this.choosenYear = this.reportValue.choosenYear;
    this.radialDetail = this.reportValue.radialDetail;
    this.ticker = this.reportValue.ticker;
    this.historyChart = this.reportValue.historyChart;

    this.distSectorRisk.year = this.choosenYear;
    this.distSectorRisk.ticker = this.ticker;
    this.distSectorRisk.chart = this.reportValue.sectorRiskChart;
    this.distSectorRisk.point = this.reportValue.sectorRisk;
    this.distSectorRisk.category = this.reportValue.categorySector;

    this.distRisk.year = this.choosenYear;
    this.distRisk.ticker = this.ticker;
    this.distRisk.chart = this.reportValue.riskChart;
    this.distRisk.point = this.reportValue.riskPoint;
    this.distRisk.category = this.reportValue.category;

    this.score = Math.round(this.ticker.finalScore * 100);
    let date = new Date();
    this.dateToday =
      date.getDate() +
      ' ' +
      date.toLocaleString('en-us', { month: 'long', year: 'numeric' });
  },
  mounted() {
    window.onload = function () {
      window.dispatchEvent(new Event('resize'));
    };
    //add timeout to make sure all charts are rendered
    document.onreadystatechange = () => {
      if (document.readyState == 'complete') {
        setTimeout(() => {
          this.onChartLoaded();
        }, 10000);
      }
    };
  },
  methods: {
    validateRenderComponent() {
      this.renderedComponent += 1;
    },
    onChartLoaded() {
      if (
        this.renderedComponent == TOTAL_COMPONENTS ||
        this.loadingTime >= 10000
      ) {
        this.exportPDFWithMethod();
      } else {
        setTimeout(() => {
          this.loadingTime += 1000;
          this.onChartLoaded();
        }, 1000);
      }
    },
    exportPDFWithMethod() {
      let elem = document.getElementById('pdfContainer');
      drawDOM(elem, {
        paperSize: 'A4',
        scale: 0.6,
        margin: 20,
        keepTogether: '.prevent-split'
      })
        .then((group) => {
          return exportPDF(group, { title: 'Transparently.AI' });
        })
        .then((dataUri) => {
          this.pdfSrc = dataUri;
          this.showViewer = true;
        });
    }
  }
};
</script>
