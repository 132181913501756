import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';
import login from '../components/login';
import mfa from '../components/multiFactorAuthentication';
import verificationChannel from '../components/verificationChannel';
import verificationPanel from '../components/verificationPanel';
import tnc from '../components/termAndCondition';
import createPassword from '../components/createPassword';
import resetPassword from '../components/resetPassword';
import forgotPassword from '../components/forgotPassword';

import userNavigation from '../components/user/userNavigation';
import userDashboard from '../components/user/userDashboard';
import userDashBoardReport from '../components/dashboard/dashboardReport';

import adminNavigation from '../components/admin/adminNavigation';
import adminTenant from '../components/admin/adminTenant';
import adminUser from '../components/admin/adminUser';
import adminAnnouncement from '../components/admin/adminAnnouncement';
import adminTermAndCondition from '../components/admin/adminTermAndCondition';
import adminSetting from '../components/admin/adminSetting';
import addCompanyPage from '@/components/tenantAdmin/addCompanyPage.vue';
import manageUsers from '@/components/tenantAdmin/manageUsers.vue';
import manageTenantUsers from '@/components/tenantUserAdmin/components/manageTenantUsers.vue';
import createUser from '@/components/tenantAdmin/createUser.vue';
import subscriptionPlansPage from '@/components/tenantAdmin/subscriptionPlansPage.vue';
import paymentPage from '@/components/tenantAdmin/paymentPage.vue';
import cancelSubscriptionPage from '@/components/tenantAdmin/cancelSubscriptionPage.vue';
import { checkIsUserUsingTrialSubscription } from '@/shared/utils/subscription';

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    /* COMMON - Start */
    {
      path: '/',
      name: 'login',
      component: login
    },
    {
      path: '/verification',
      name: 'verification',
      component: verificationChannel
    },
    {
      path: '/verifyCode',
      name: 'verifyCode',
      component: verificationPanel
    },
    {
      path: '/mfaSignIn',
      name: 'mfa',
      component: mfa,
      meta: { isMFA: true }
    },
    {
      path: '/createPassword',
      name: 'createPassword',
      component: createPassword
    },
    {
      path: '/termsAndConditions',
      name: 'tnc',
      component: tnc,
      meta: { isTNC: true }
    },
    {
      path: '/forgotPassword',
      name: 'forgotPassword',
      component: forgotPassword
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      component: resetPassword
    },
    /* COMMON - End */

    /* USER PORTAL - Start */
    {
      path: '',
      component: userNavigation,
      props: true,
      children: [
        {
          path: '/dashboard',
          component: userDashboard,
          props: true,
          meta: { requiresAuth: true, requiresCheckForSubscription: true }
        }
      ]
    },
    {
      path: '/report',
      name: 'report',
      component: userDashBoardReport,
      props: true,
      meta: { requiresAuth: true }
    },
    /* USER PORTAL - End */

    /* ADMIN PORTAL - Start */
    {
      path: '',
      component: adminNavigation,
      props: true,
      children: [
        {
          path: '/administrator/tenant',
          component: adminTenant,
          props: true,
          meta: { requiresAuth: true, role: 'Administrator' }
        },
        {
          path: '/administrator/tenant/:tenantId',
          component: adminUser,
          props: true,
          meta: { requiresAuth: true, role: 'Administrator' }
        },
        {
          path: '/administrator/announcement',
          component: adminAnnouncement,
          props: true,
          meta: { requiresAuth: true, role: 'Administrator' }
        },
        {
          path: '/administrator/termandcondition',
          component: adminTermAndCondition,
          props: true,
          meta: { requiresAuth: true, role: 'Administrator' }
        },
        {
          path: '/administrator/setting',
          component: adminSetting,
          props: true,
          meta: { requiresAuth: true, role: 'Administrator' }
        }
      ]
    },
    /* ADMIN PORTAL - End */

    /* NON SSP TENANT ADMIN - START */
    {
      path: '/manager/tenant',
      component: manageTenantUsers,
      props: true,
      meta: { requiresAuth: true }
    },
    /* NON SSP TENANT ADMIN - END */

    /* SSP TENANT ADMIN - START */
    {
      path: '/addCompany',
      name: 'addCompany',
      component: addCompanyPage
    },
    {
      path: '/manageUsers',
      component: manageUsers,
      props: true,
      meta: { requiresAuth: true, role: 'TenantAdministrator' }
    },
    {
      path: '/createUser',
      component: createUser,
      props: true,
      meta: { requiresAuth: true, role: 'TenantAdministrator' }
    },
    /* SSP TENANT ADMIN - END */

    /* PAYMENT - START */
    {
      path: '/subscriptionPlans',
      name: 'subscriptionPlans',
      component: subscriptionPlansPage
    },
    {
      path: '/payment',
      name: 'payment',
      component: paymentPage,
      meta: {
        requiresAuth: true,
        role: 'TenantAdministrator',
        notAllowedWhenUsingTrial: true
      }
    },
    {
      path: '/cancel-subscription',
      name: 'cancelSubscription',
      component: cancelSubscriptionPage,
      meta: {
        requiresAuth: true,
        role: 'TenantAdministrator',
        notAllowedWhenUsingTrial: true,
        requiresCheckForSubscription: true
      }
    }
    /* PAYMENT - END */
  ]
});

router.beforeEach((to, from, next) => {
  const { idToken, isAgree, administrator, tenantAdministrator, sessionInfo } =
    store.state.principal;
  const isAuthenticated = !!(idToken && isAgree);

  const { subscription } = store.state.fastspring;
  const { fastspringSubscriptionId } = subscription || {};
  const isSubscriptionAvailable =
    store.getters['fastspring/isSubscriptionAvailable'];

  if (
    to.matched.some(
      ({ meta }) => meta.requiresAuth && meta.role === 'Administrator'
    )
  ) {
    if (isAuthenticated && administrator) {
      next();
      return;
    }

    next('/administrator');
  }

  if (
    to.matched.some(
      ({ meta }) => meta.requiresAuth && meta.role === 'TenantAdministrator'
    )
  ) {
    if (isAuthenticated && tenantAdministrator) {
      const isUserUsingTrialSubscription =
        checkIsUserUsingTrialSubscription(subscription);

      //User is using trial subscription and has activated it
      if (
        to.matched.some(({ meta }) => meta.notAllowedWhenUsingTrial) &&
        isUserUsingTrialSubscription &&
        fastspringSubscriptionId
      ) {
        next('/manageUsers');
        return;
      }

      if (to.matched.some(({ meta }) => meta.requiresCheckForSubscription)) {
        if (subscription && !isSubscriptionAvailable) {
          next('/manageUsers');
          return;
        }
      }

      next();
      return;
    }

    next('/');
  }

  if (to.matched.some(({ meta }) => meta.requiresCheckForSubscription)) {
    if (subscription && !isSubscriptionAvailable) {
      next('/manageUsers');
      return;
    }
  }

  if (to.matched.some(({ meta }) => meta.requiresAuth && !meta.role)) {
    if (isAuthenticated) {
      next();
      return;
    }

    if (to.path === '/dashboard' && to.query.share) {
      next({ path: '/', query: { share: to.query.share } });
      return;
    }

    next('/');
  } else {
    if (to.matched.some(({ meta }) => meta.isMFA)) {
      if (sessionInfo) {
        next();
        return;
      }

      next('/');
    } else if (to.matched.some(({ meta }) => meta.isTNC)) {
      if (idToken) {
        next();
        return;
      }

      next('/');
    }

    next();
  }
});

export default router;
