import { getter } from '@progress/kendo-vue-common';

const emailRegex = new RegExp(
  /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+([a-zA-Z]){2,7}$/
);
const phoneRegex = new RegExp(
  /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,14}$/
);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);
const sixDigitRegex = new RegExp(/\w{6}/);
const alphanumericRegex = new RegExp(/^[a-zA-Z0-9-]+$/);

export const termsValidator = (value) =>
  value ? '' : "It's required to agree with Terms and Conditions.";

export const emailValidator = (value) =>
  !value
    ? 'Email field is required.'
    : emailRegex.test(value)
    ? ''
    : 'Email is not in a valid format.';

export const nameValidator = (value) =>
  !value
    ? 'Full Name is required'
    : value.length < 7
    ? 'Full Name should be at least 7 characters long.'
    : '';

export const phoneValidator = (value) =>
  !value
    ? 'Phone number is required.'
    : phoneRegex.test(value)
    ? ''
    : 'Not a valid phone number.';

export const cardValidator = (value) =>
  !value
    ? 'Credit card number is required. '
    : ccardRegex.test(value)
    ? ''
    : 'Not a valid credit card number format.';

export const cvcValidator = (value) =>
  !value
    ? 'CVC code is required,'
    : cvcRegex.test(value) || value.length !== 3
    ? ''
    : 'Not a valid CVC code format.';

export const requiredValidator = (value) =>
  value ? '' : 'Error: This field is required.';

export const passwordValidator = (value) =>
  value && value.length > 8 ? '' : 'Password must be at least 8 symbols.';

export const addressValidator = (value) =>
  value ? '' : 'Address is required.';

export const regionValidator = (value) => (value ? '' : 'Region is required.');

const emailGetter = getter('email');
const passwordGetter = getter('password');
const newPasswordGetter = getter('newPassword');
const confirmPasswordGetter = getter('confirmPassword');
const codeGetter = getter('code');
// const uidGetter = getter('uid');
const displayNameGetter = getter('displayName');
const phoneNumberGetter = getter('phoneNumber');
// const statusGetter = getter('status');
const organizationNameGetter = getter('organizationName');
const organizationUrlGetter = getter('organizationUrl');
const contactNameGetter = getter('contactName');
const contactEmailGetter = getter('contactEmail');
const contactPhoneGetter = getter('contactPhone');
const startDateGetter = getter('startDate');
const endDateGetter = getter('endDate');
const regionTenantGetter = getter('region');

export const loginFormValidator = (values) => {
  const email = emailGetter(values);
  const password = passwordGetter(values);

  if (email && emailRegex.test(email) && password) {
    return {};
  }

  return {
    summary: 'EMAIL_PASSWORD_EMPTY',
    ['email']: !email
      ? 'EMAIL_PASSWORD_EMPTY'
      : emailRegex.test(email)
      ? ''
      : 'INVALID_EMAIL_FORMAT',
    ['password']: !password ? 'EMAIL_PASSWORD_EMPTY' : ''
  };
};

export const forgotPasswordFormValidator = (values) => {
  const email = emailGetter(values);

  if (email && emailRegex.test(email)) {
    return {};
  }

  return {
    ['email']: !email
      ? 'EMPTY_EMAIL'
      : emailRegex.test(email)
      ? ''
      : 'INVALID_EMAIL_FORMAT'
  };
};

export const resetPasswordFormValidator = (values) => {
  const newPassword = newPasswordGetter(values);
  const confirmPassword = confirmPasswordGetter(values);

  if (newPassword && confirmPassword && newPassword == confirmPassword) {
    return;
  }

  return {
    summary: 'EMPTY_PASSWORD',
    ['newPassword']: !newPassword ? 'EMPTY_PASSWORD' : '',
    ['confirmPassword']: !confirmPassword
      ? 'EMPTY_PASSWORD'
      : newPassword == confirmPassword
      ? ''
      : 'PASSWORD_NOT_MATCH'
  };
};

export const mfaCodeValidator = (values) => {
  const code = codeGetter(values);

  if (code && sixDigitRegex.test(code)) {
    return {};
  }

  return {
    ['code']: !code
      ? 'EMPTY_OTP_CODE'
      : sixDigitRegex.test(code)
      ? ''
      : 'EMPTY_OTP_CODE'
  };
};

export const userFormValidator = (values) => {
  // const uid = uidGetter(values);
  const displayName = displayNameGetter(values);
  const email = emailGetter(values);
  const phoneNumber = phoneNumberGetter(values);
  // const status = statusGetter(values);

  if (
    displayName &&
    email &&
    emailRegex.test(email) &&
    phoneNumber &&
    phoneRegex.test(phoneNumber) &&
    status
  ) {
    return {};
  }

  return {
    // ['uid']:
    // 	!uid
    // 		? 'User ID is required.'
    // 		: '',
    ['displayName']: !displayName ? 'User Name is required.' : '',
    ['email']: !email
      ? 'Email is required.'
      : emailRegex.test(email)
      ? ''
      : 'Please enter a valid email.',
    ['phoneNumber']: !phoneNumber
      ? 'Phone is required.'
      : phoneRegex.test(phoneNumber)
      ? ''
      : 'Not a valid phone number.'
    // ['status']:
    // 	!status
    // 		? 'Status is required.'
    // 		: '',
  };
};

export const tenantFormValidator = (values) => {
  const organizationName = organizationNameGetter(values);
  const organizationUrl = organizationUrlGetter(values);
  const contactName = contactNameGetter(values);
  const contactEmail = contactEmailGetter(values);
  const contactPhone = contactPhoneGetter(values);
  const startDate = startDateGetter(values);
  const endDate = endDateGetter(values);
  const region = regionTenantGetter(values);

  // if (organizationName && organizationUrl && contactName && contactEmail && emailRegex.test(contactEmail) && contactPhone && phoneRegex.test(contactPhone) && startDate && endDate && new Date(startDate) <= new Date(endDate)) {
  if (
    region &&
    organizationName &&
    organizationUrl &&
    contactName &&
    contactEmail &&
    emailRegex.test(contactEmail) &&
    contactPhone &&
    phoneRegex.test(contactPhone) &&
    startDate &&
    endDate &&
    new Date(startDate) <= new Date(endDate)
  ) {
    return {};
  }

  return {
    ['region']: !region ? 'Region is required.' : '',
    ['organizationName']: !organizationName
      ? 'Organization Name is required.'
      : alphanumericRegex.test(organizationName)
      ? ''
      : 'INVALID_DISPLAY_NAME',
    ['organizationUrl']: !organizationUrl
      ? 'Organization URL is required.'
      : '',
    ['contactName']: !contactName ? 'Contact Name is required.' : '',
    ['contactEmail']: !contactEmail
      ? 'Contact Email is required.'
      : emailRegex.test(contactEmail)
      ? ''
      : 'Please enter a valid email.',
    ['contactPhone']: !contactPhone
      ? 'Contact Phone is required.'
      : phoneRegex.test(contactPhone)
      ? ''
      : 'Not a valid phone number.',
    ['startDate']: !startDate ? 'Start Date is required.' : '',
    ['endDate']: !endDate
      ? 'End Date is required.'
      : new Date(startDate) <= new Date(endDate)
      ? ''
      : 'End Date must greater than Start Access required.'
  };
};

const keyGetter = getter('key');
const valueGetter = getter('value');

export const settingFormValidator = (values) => {
  const settingKey = keyGetter(values);
  const settingValue = valueGetter(values);

  if (settingKey && settingValue) {
    return {};
  }

  return {
    ['settingKey']: !settingKey ? 'Setting Key is required.' : '',
    ['settingValue']: !settingValue ? 'Setting Value is required.' : ''
  };
};

const ticketTitleGetter = getter('ticketTitle');
const ticketBodyGetter = getter('ticketBody');

export const createIssueTicketValidator = (values) => {
  const ticketTitle = ticketTitleGetter(values);
  const ticketBody = ticketBodyGetter(values);

  if (ticketTitle && ticketBody) {
    return {};
  }

  return {
    summary: "Please enter ticket's title and body. Both are required.",
    ['ticketTitle']: !ticketTitle ? 'Ticket Title is required.' : '',
    ['ticketBody']: !ticketBody ? 'Ticket Body is required.' : ''
  };
};
