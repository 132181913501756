export default {
  methods: {
    handleShowErrorNotification(error) {
      const DEFAULT_MESSAGE = 'An unknown error has occurred';

      let notification = { title: 'Unknown error', content: '' };

      if (error.response) {
        if (error.response.status === 400) {
          notification.code = 400;
          notification.title = 'Bad request';
        } else if (error.response.status === 401) {
          notification.code = 401;
          notification.title = 'Unauthorized';

          this.$store.dispatch('logout').then(() => {
            this.$router.push('/');
          });
        } else if (error.response.status === 403) {
          notification.code = 403;
          notification.title = 'Forbidden';
        } else if (error.response.status === 404) {
          notification.code = 404;
          notification.title = 'Not found';
        } else if (error.response.status === 500) {
          notification.code = 500;
          notification.title = 'Server error';
          if (
            error.response.data.details.includes(
              'a foreign key constraint fails'
            )
          ) {
            let details = error.response.data.details.split(':');
            notification.content = details[3];
          } else {
            notification.content = error.response.data.details;
          }
        } else {
          notification.title = 'Notification (' + error.response.status + ')';
        }

        if (error.response.status !== 500) {
          const errorMessage =
            error.response.data.errorCode || error.response.data.message;
          const errorDataMessage =
            error.response.data && error.response.data.error
              ? error.response.data.error.message
              : '';

          notification.content = this.$te(errorMessage)
            ? errorMessage
            : errorDataMessage || error.response.data || DEFAULT_MESSAGE;
        }

        this.$store.commit('showNotification', notification);
      }
    }
  }
};
