import { checkIsAnnuallySubscription } from './subscription';
import { ANNUAL_SUBSCRIPTION_COUPON } from '../constants/fastspring';

export const SUBSCRIPTION_STATE = {
  ACTIVE: 'active',
  CANCELED: 'canceled'
};

export const getDropdownRegions = (state) => {
  return checkIsSubscribed(state)
    ? state.dropdownCostlyRegions
    : state.dropdownBundleRegions;
};

export const checkIsSubscribed = (state) => {
  return state.subscription && state.subscription.seatCount > 0;
};

export const pickRegionFields = (t) => ({
  id: t.pid,
  bigqueryRegionId:
    parseInt((t.attributes || {}).bigqueryRegionId) ||
    parseInt((t.attributes || {}).regionID),
  text: t.display || '',
  image: t.image,
  price: t.price,
  selected: t.selected || false,
  disabled: t.disabled || false
});

export const processBundle = (state, bundle) => {
  const { seatCount, regionCount } = bundle.attributes || {};

  bundle.seatCount = parseInt(seatCount);
  bundle.regionCount = parseInt(regionCount);
  bundle.extraSeat = ((
    (bundle.groups || []).find((t) => t.display === 'ExtraSeat') || {}
  ).items || [])[0];

  if (state.selectedBundle && state.selectedBundle.pid === bundle.pid) {
    const group = (bundle.groups || []).find((t) => t.display === 'Regions');
    const bundleRegionsGroup = (bundle.groups || []).find(
      (t) => t.display === 'BundleRegions'
    );

    if (group && group.items) addRegions(state, group.items);
    if (bundleRegionsGroup && bundleRegionsGroup.items)
      addRegions(
        state,
        bundleRegionsGroup.items,
        'bundleRegions',
        'dropdownBundleRegions'
      );
  }
};

export function addRegions(
  state,
  items,
  regionsKey = 'costlyRegions',
  dropdownRegionsKey = 'dropdownCostlyRegions'
) {
  const regionCountriesMap = {};
  const otherCountries = [];

  items.forEach((product) => {
    const region = (product.attributes || {}).group;

    if (!region) {
      otherCountries.push(product);
      return;
    }

    if (regionCountriesMap[region]) {
      regionCountriesMap[region].push(product);
    } else {
      regionCountriesMap[region] = [product];
    }
  });

  state[regionsKey] = Object.entries(regionCountriesMap)
    .sort()
    .map(([key, value]) => ({
      region: key,
      countries: value.sort((a, b) =>
        a.display < b.display ? -1 : a.display > b.display ? 1 : 0
      )
    }));

  state[regionsKey].push({ region: 'Other', countries: otherCountries });

  state[dropdownRegionsKey] = state[regionsKey].map((product) => ({
    region: product.region,
    countries: product.countries.map(pickRegionFields)
  }));
}

export const removeUnselectedRegionIds = (value) => (region) => {
  region.countries.forEach((country) => {
    if (
      !value.find(
        ({ bigqueryRegionId }) => bigqueryRegionId === country.bigqueryRegionId
      )
    ) {
      country.selected = false;
    }
  });
};

export const buildUserSubscriptionInformation = (
  { rootState, state, getters },
  userSubscriptionDetail
) => {
  const { tenantId } = rootState.principal;

  if (state.selectedBundle) {
    state.selectedBundle.subscription.nextChargeTotal = state.totalPrice;
    state.selectedBundle.subscription.nextChargeTotalValue =
      state.totalPriceValue;
  }

  const isAnnuallySubscription = checkIsAnnuallySubscription(
    state.selectedBundle
  );

  const {
    nextChargeDate,
    nextChargeDateValue,
    nextChargeTotal,
    nextChargeTotalValue
  } = userSubscriptionDetail || {};

  const {
    fastspringSubscriptionId,
    regionCount,
    regionProductIds,
    tenant,
    seatCount
  } = state.subscription || {};
  const selectedRegions = getters.selectedRegions
    .map((t) => t.id)
    .filter((t) => t);
  const selectedRegionIds = getters.selectedRegions
    .map((t) => t.bigqueryRegionId)
    .filter((t) => t);
  const selectedSeatCount = state.selectedBundle
    ? Number(state.selectedBundle.attributes.seatCount) + state.extraSeatCount
    : 0;
  const currentUserRegions = regionProductIds
    ? JSON.parse(regionProductIds.length > 0 ? regionProductIds : '[]')
    : [];
  const selectedRegionsProductIds =
    selectedRegions && selectedRegions.length > 0
      ? selectedRegions
      : currentUserRegions;
  const removedRegionProductIds = [];
  const addonProductIds = [];

  for (const region of currentUserRegions) {
    if (!selectedRegionsProductIds.includes(region)) {
      removedRegionProductIds.push(region);
    }
  }

  for (const region of selectedRegionsProductIds) {
    if (!currentUserRegions.includes(region)) {
      addonProductIds.push(region);
    }
  }

  const requestData = {
    companyId: tenantId,
    fastspringSubscriptionId,
    extraSeatCount: state.extraSeatCount,
    regionProductIds: selectedRegionsProductIds,
    regionIds:
      selectedRegionIds && selectedRegionIds.length > 0
        ? selectedRegionIds
        : JSON.parse(
            tenant.region && tenant.region.length > 0 ? tenant.region : '[]'
          ),
    seatCount: selectedSeatCount || seatCount,
    regionCount: getters.selectedRegions.length || regionCount,
    nextChargeDate,
    nextChargeDateValue,
    nextChargeTotal,
    nextChargeTotalValue,
    removedRegionProductIds,
    addonProductIds,
    ...(isAnnuallySubscription ? { coupons: [ANNUAL_SUBSCRIPTION_COUPON] } : {})
  };

  return requestData;
};
